import {useState,useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {TablesWidget11} from '../../../_metronic/partials/widgets/tables/TablesWidget11'
import { useAuthenticatedRequest } from '../Components/Requests'

const GetaquotePage = () => {
   const [data, setData] = useState<any[]>([])

   const [pagination, setPagination] = useState({
     totalPages: 0,
     totalResults: 0,
     currentPage: 1,
   })

  const endpoint = '/v1/cables/admin/contact-us?type=get_quotation'

  useEffect(() => {
    fetchData(endpoint, pagination.currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, pagination.currentPage])
  const {getRequest} = useAuthenticatedRequest()

  const fetchData = async (url: string, page: number) => {
    try {
      const response = await getRequest(url, {
        page: page.toString(),
      })
      const result = response.data
      setData(result)
      setPagination({
        totalPages: result.totalPages,
        totalResults: result.totalResults,
        currentPage: page,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <TablesWidget11 className={''} data={data} />
    </>
  )
}

const GetaquoteWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.GETAQUOTE'})}</PageTitle>
      <GetaquotePage />
    </>
  )
}

export {GetaquoteWrapper}
