/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {AuthModel} from '../core/_models'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [errorMessage, setErrorMessage] = useState('') // Step 1: State variable for error message

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        const user = auth.user
        const {
          access: {token: accessToken},
          refresh: {token: refreshToken},
        } = auth.tokens
        const updatedAuth: AuthModel = {
          user: auth.user,
          tokens: {
            access: {token: accessToken},
            refresh: {token: refreshToken},
          },
        }
        saveAuth(updatedAuth)
        setCurrentUser(user)
      } catch (error) {
        saveAuth(undefined)
        console.error(error)
        setStatus('The login details are incorrect')
        setErrorMessage('The login details are incorrect') // Step 2: Set the error message

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div
      className='d-flex justify-content-center align-items-center vh-100'
      style={{backgroundColor: '#1b1b28'}}
    >
      <div className='card w-25'>
        <div className='text-center mb-11'>
          <div className='w-100 py-3 mb-3' style={{backgroundColor: '#933d8b'}}>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/demo6.png')} className='h-40px' />
          </div>
          <h1 className='text-dark fw-bolder mt-3'>Sign In</h1>
        </div>
        <form
          className='form w-100  p-5'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          {/* begin::Heading */}
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
              placeholder='Password'
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            {/* <Link to='/auth/forgot-password' className='link-primary'>
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}
          {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}{' '}
          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
          {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
            Not a Member yet?{' '}
            <Link to='/auth/registration' className='link-primary'>
              Sign up
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  )
}
