import {useState} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '..//Components/Requests'
import {KTIcon} from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {AsyncPaginate} from 'react-select-async-paginate'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint}) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const [selectedOption, setSelectedOption] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [modal, setModal] = useState(false)
  const addSchema = Yup.object().shape({
    value_en: Yup.string().required('Value English is required'),
    value_ar: Yup.string().required('Value Arabic is required'),
    type: Yup.string().required('Type is required'),
  })

  const formik = useFormik({
    validationSchema: addSchema,
    initialValues: {
      value_en: '',
      value_ar: '',
      type: '',
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await postRequest(endpoint, values)
        setChange(!change)
        resetForm()
        setModal(false)
        setSelectedOption(null)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_add_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setModal(true)
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const options = [
    {
      label: 'Product Series',
      value: 'product_series',
    },
    {
      label: 'Product Type',
      value: 'product_type',
    },
    {
      label: 'Cable Type',
      value: 'cable_type',
    },
    {
      label: 'Fiber Type',
      value: 'fiber_type',
    },
    {
      label: 'Outer Sheathing Material',
      value: 'outer_sheathing_material',
    },
    {
      label: 'Core Filling',
      value: 'core_filling',
    },
    {
      label: 'Armoring',
      value: 'armoring',
    },
    {
      label: 'Euro lassification to cpr',
      value: 'euro_classification_to_cpr',
    },
    {
      label: 'Strength Members',
      value: 'strength_members',
    },
    {
      label: 'Fiber Count',
      value: 'fiber_count',
    },
    {
      label: 'Jacketing Color',
      value: 'jacketing_color',
    },
  ]

  return (
    <div className='modal fade' id='kt_modal_add_Sub' aria-hidden='true' role='dialog'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body  pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Specification</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              {/* Form fields */}
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Value English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Value English'
                      {...formik.getFieldProps('value_en')}
                      className='form-control'
                      name='value_en'
                    />
                    {formik.touched.value_en && formik.errors.value_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.value_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Value Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Value Arabic'
                      {...formik.getFieldProps('value_ar')}
                      className='form-control'
                      name='value_ar'
                    />
                    {formik.touched.value_ar && formik.errors.value_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.value_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Type</label>
                    <AsyncPaginate
                      loadOptions={(searchQuery, prevOptions) => {
                        return Promise.resolve({
                          options: options,
                          hasMore: false,
                        })
                      }}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={selectedOption}
                      onChange={(option) => {
                        setSelectedOption(option)
                        formik.setFieldValue('type', option?.value)
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                    />
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.type}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* End of form fields */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
