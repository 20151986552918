import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId, id}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [inputValue, setInputValue] = useState<string>('')
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
        setData(response.data.data)

        const initialSeries = {
          label: response.data.data.product_series.value,
          value: response.data.data.product_series.id,
        }

        const initialType = {
          label: response.data.data.product_type.value,
          value: response.data.data.product_type.id,
        }

        setSelectedOptions({
          product_series_id: initialSeries.value,
          product_type_id: initialType.value,
          product_series_value: initialSeries.label,
          product_type_value: initialType.label,
        })

        formik.setValues({
          product_series_id: initialSeries.value,
          product_type_id: initialType.value,
          product_id: id || '',
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const initialSelectedOptions = {
    product_series_id: null,
    product_type_id: null,
    product_series_value: null,
    product_type_value: null,
  }

  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)

  const formik = useFormik({
    initialValues: {
      product_series_id: '',
      product_type_id: '',
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, value]) => value !== '')
      )
      try {
        await editRequest(selectedRowId, endpoint, filteredValues)
        setChange(!change)
        setModal(false)
        setLoading(false)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_edit_clas')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const loadOptions = async (searchQuery: string, type: string) => {
    const response = await axios.get(`${API_URL}/v1/cables/admin/product-specifications?type=${type}`, {
      params: {query: searchQuery},
    })

    const options = response.data.data.data.map((item: any) => ({
      value: item.id,
      label: item.value,
    }))

    return {
      options,
      hasMore: false,
      additional: {
        page: 1,
      },
    }
  }

  return (
    <div className='modal fade' id='kt_modal_edit_clas' aria-hidden='true' role='dialog'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Edit Classification</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              {/* Form fields */}
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='fs-5 fw-semibold mb-2'>Product Series</label>
                    <AsyncPaginate
                      loadOptions={(searchQuery) => loadOptions(searchQuery, 'product_series')}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      value={
                        selectedOptions.product_series_id
                          ? {
                              label: selectedOptions.product_series_value,
                              value: selectedOptions.product_series_id,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setSelectedOptions((prevOptions) =>
                          option
                            ? {
                                product_series_id: option.value,
                                product_series_value: option.label,
                                product_type_id: prevOptions.product_type_id,
                                product_type_value: prevOptions.product_type_value,
                              }
                            : {
                                product_series_id: null,
                                product_series_value: null,
                                product_type_id: null,
                                product_type_value: null,
                              }
                        )
                        formik.setFieldValue('product_series_id', option ? option.value : '')
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className='fs-5 fw-semibold mb-2'>Product Type</label>
                    <AsyncPaginate
                      loadOptions={(searchQuery) => loadOptions(searchQuery, 'product_type')}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      value={
                        selectedOptions.product_type_id
                          ? {
                              label: selectedOptions.product_type_value,
                              value: selectedOptions.product_type_id,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setSelectedOptions((prevOptions) =>
                          option
                            ? {
                                product_series_id: prevOptions.product_series_id,
                                product_series_value: prevOptions.product_series_value,
                                product_type_id: option.value,
                                product_type_value: option.label,
                              }
                            : {
                                product_series_id: null,
                                product_series_value: null,
                                product_type_id: null,
                                product_type_value: null,
                              }
                        )
                        formik.setFieldValue('product_type_id', option ? option.value : '')
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                    />
                  </div>

                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                    <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                      <span className='indicator-label'>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
