/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
        setData(response.data.data)

        formik.setValues({
          title_en: response.data.data.title_en || '',
          title_ar: response.data.data.title_ar || '',
          description_en: response.data.data.description_en || '',
          description_ar: response.data.data.description_ar || '',
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const initialValues = {
    title_en: data.title_en || '',
    title_ar: data.title_ar || '',
    description_en: data.title_ar || '',
    description_ar: data.title_ar || '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await editRequest(selectedRowId, endpoint, values)
        setChange(!change)
        setModal(false)
        resetForm()
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_edit_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error) {
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Sub' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit {data.title_en}</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='fs-5 fw-semibold mb-2'>Title English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('title_en')}
                        className='form-control'
                        name='title_en'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('title_ar')}
                        className='form-control'
                        name='title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description English</label>
                      <ReactQuill
                        value={formik.values.description_en}
                        onChange={(value) => formik.setFieldValue('description_en', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description English'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description Arabic</label>
                      <ReactQuill
                        value={formik.values.description_ar}
                        onChange={(value) => formik.setFieldValue('description_ar', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description Arabic'
                      />
                    </div>
                    <div className='col-md-12 mt-5 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
