import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import {toast} from 'react-toastify'

export const Specfications = ({id}) => {
  const [loading, setLoading] = useState(false)
  const {editRequest, postRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [inputValue, setInputValue] = useState<string>('')
  const endpoint = `/v1/cables/admin/product-general-specifications`

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}?product_id=${id}`).then((response) => {
      setData(response.data.data)
      console.log(response.data.data)
      
      const initialcable_type = {
        label: response.data.data.cable_type?.value ?? '',
        value: response.data.data.cable_type?.id ?? '',
      }
      const initialfiber_type = {
        label: response.data.data.fiber_type?.value ?? '',
        value: response.data.data.fiber_type?.id ?? '',
      }

      const initialouter_sheathing_material = {
        label: response.data.data.outer_sheathing_material?.value ?? '',
        value: response.data.data.outer_sheathing_material?.id ?? '',
      }
      const initialcore_filling = {
        label: response.data.data.core_filling?.value ?? '',
        value: response.data.data.core_filling?.id ?? '',
      }
      const initialarmoring = {
        label: response.data.data.armoring?.value ?? '',
        value: response.data.data.armoring?.id ?? '',
      }

      const initialeuro_classification_to_cpr = {
        label: response.data.data.euro_classification_to_cpr?.value ?? '',
        value: response.data.data.euro_classification_to_cpr?.id ?? '',
      }
      const initialstrength_members = {
        label: response.data.data.strength_members?.value ?? '',
        value: response.data.data.strength_members?.id ?? '',
      }

      const initialfiber_count = {
        label: response.data.data.fiber_count?.value ?? '',
        value: response.data.data.fiber_count?.id ?? '',
      }
      const initialjacketing_color = {
        label: response.data.data.jacketing_color?.value ?? '',
        value: response.data.data.jacketing_color?.id ?? '',
      }


      setSelectedOptions({
        cable_type_id: initialcable_type.value ?? '',
        cable_type_value: initialcable_type.label ?? '',
        fiber_type_id: initialfiber_type.value ?? '',
        fiber_type_value: initialfiber_type.label ?? '',
        outer_sheathing_material_id: initialouter_sheathing_material.value ?? '',
        outer_sheathing_material_value: initialouter_sheathing_material.label ?? '',
        core_filling_id: initialcore_filling.value ?? '',
        core_filling_value: initialcore_filling.label ?? '',
        armoring_id: initialarmoring.value ?? '',
        armoring_value: initialarmoring.label ?? '',
        euro_classification_to_cpr_id: initialeuro_classification_to_cpr.value ?? '',
        euro_classification_to_cpr_value: initialeuro_classification_to_cpr.label ?? '',
        strength_members_id: initialstrength_members.value ?? '',
        strength_members_value: initialstrength_members.label ?? '',
        fiber_count_id: initialfiber_count.value ?? '',
        fiber_count_value: initialfiber_count.label ?? '',
        jacketing_color_id: initialjacketing_color.value ?? '',
        jacketing_color_value: initialjacketing_color.label ?? '',
      })

      formik.setValues({
        ...formik.values, // Preserve other form values
        cable_type_id: selectedOptions.cable_type_id ?? '',
        fiber_type_id: selectedOptions.fiber_type_id ?? '',
        outer_sheathing_material_id: selectedOptions.outer_sheathing_material_id ?? '',
        core_filling_id: selectedOptions.core_filling_id ?? '',
        armoring_id: selectedOptions.armoring_id ?? '',
        euro_classification_to_cpr_id: selectedOptions.euro_classification_to_cpr_id ?? '',
        strength_members_id: selectedOptions.strength_members_id ?? '',
        fiber_count_id: selectedOptions.fiber_count_id ?? '',
        jacketing_color_id: selectedOptions.jacketing_color_id ?? '',
      })
    })
  }, [API_URL, endpoint])

  const initialSelectedOptions = {
    cable_type_id: '',
    cable_type_value: '',
    fiber_type_id: '',
    fiber_type_value: '',
    outer_sheathing_material_id: '',
    outer_sheathing_material_value: '',
    core_filling_id: '',
    core_filling_value: '',
    armoring_id: '',
    armoring_value: '',
    euro_classification_to_cpr_id: '',
    euro_classification_to_cpr_value: '',
    strength_members_id: '',
    strength_members_value: '',
    fiber_count_id: '',
    fiber_count_value: '',
    jacketing_color_id: '',
    jacketing_color_value: '',
  }
  const SpecificationID = data && data.id

  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  const formik = useFormik({
    initialValues: {
      cable_type_id: selectedOptions.cable_type_id || '', // Make sure the field name matches
      fiber_type_id: selectedOptions.fiber_type_id || '',
      outer_sheathing_material_id: selectedOptions.outer_sheathing_material_id || '',
      core_filling_id: selectedOptions.core_filling_id || '',
      armoring_id: selectedOptions.armoring_id || '',
      euro_classification_to_cpr_id: selectedOptions.euro_classification_to_cpr_id || '',
      strength_members_id: selectedOptions.strength_members_id || '',
      fiber_count_id: selectedOptions.fiber_count_id || '',
      jacketing_color_id: selectedOptions.jacketing_color_id || '',
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        if (!data || data.totalResults == 0) {
          await postRequest(endpoint, values)
        } else {
          await editRequest(SpecificationID, endpoint, values)
        }
        toast.success('Form submitted successfully')
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const loadOptions = async (searchQuery: string, type: string) => {
    const response = await axios.get(`${API_URL}/v1/cables/admin/product-specifications?type=${type}`, {
      params: {query: searchQuery},
    })

    const options = response.data.data.data.map((item: any) => ({
      value: item.id,
      label: item.value,
    }))

    return {
      options,
      hasMore: false,
      additional: {
        page: 1,
      },
    }
  }

  console.log(selectedOptions)

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
        {/* Form fields */}
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <label className='fs-5 fw-semibold mb-2'>Cable Type</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'cable_type')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.cable_type_id
                    ? {
                        label: selectedOptions.cable_type_value,
                        value: selectedOptions.cable_type_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    cable_type_id: option ? option.value : '',
                    cable_type_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('cable_type_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6'>
              <label className='fs-5 fw-semibold mb-2'>Fiber Type</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'fiber_type')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.fiber_type_id
                    ? {
                        label: selectedOptions.fiber_type_value,
                        value: selectedOptions.fiber_type_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    fiber_type_id: option ? option.value : '',
                    fiber_type_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('fiber_type_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>

            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Outer Sheathing Material</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'outer_sheathing_material')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.outer_sheathing_material_id
                    ? {
                        label: selectedOptions.outer_sheathing_material_value,
                        value: selectedOptions.outer_sheathing_material_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    outer_sheathing_material_id: option ? option.value : '',
                    outer_sheathing_material_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('outer_sheathing_material_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Core Filling</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'core_filling')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.core_filling_id
                    ? {
                        label: selectedOptions.core_filling_value,
                        value: selectedOptions.core_filling_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    core_filling_id: option ? option.value : '',
                    core_filling_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('core_filling_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Armoring</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'armoring')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.armoring_id
                    ? {
                        label: selectedOptions.armoring_value,
                        value: selectedOptions.armoring_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    armoring_id: option ? option.value : '',
                    armoring_calue: option ? option.label : '',
                  }))

                  formik.setFieldValue('armoring_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Fiber Category</label>
              <AsyncPaginate
                loadOptions={(searchQuery) =>
                  loadOptions(searchQuery, 'euro_classification_to_cpr')
                }
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.euro_classification_to_cpr_id
                    ? {
                        label: selectedOptions.euro_classification_to_cpr_value,
                        value: selectedOptions.euro_classification_to_cpr_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    euro_classification_to_cpr_id: option ? option.value : '',
                    euro_classification_to_cpr_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('euro_classification_to_cpr_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Strength Members</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'strength_members')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.strength_members_id
                    ? {
                        label: selectedOptions.strength_members_value,
                        value: selectedOptions.strength_members_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    strength_members_id: option ? option.value : '',
                    strength_members_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('strength_members_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Fiber Count</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'fiber_count')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.fiber_count_id
                    ? {
                        label: selectedOptions.fiber_count_value,
                        value: selectedOptions.fiber_count_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    fiber_count_id: option ? option.value : '',
                    fiber_count_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('fiber_count_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Jacketing Color</label>
              <AsyncPaginate
                loadOptions={(searchQuery) => loadOptions(searchQuery, 'jacketing_color')}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                value={
                  selectedOptions.jacketing_color_id
                    ? {
                        label: selectedOptions.jacketing_color_value,
                        value: selectedOptions.jacketing_color_id,
                      }
                    : null
                }
                onChange={(option) => {
                  setSelectedOptions((prevOptions) => ({
                    ...prevOptions, // Preserve other fields
                    jacketing_color_id: option ? option.value : '',
                    jacketing_color_value: option ? option.label : '',
                  }))

                  formik.setFieldValue('jacketing_color_id', option ? option.value : '')
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
              />
            </div>
            <div className='col-md-12 mt-5 text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
