import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {SubsidiariesWrapper} from '../modules/subsidiaries/SubsidiariesPage'
import {NewsroomWrapper} from '../modules/newsroom/NewsroomPage'
import {IndustriesWrapper} from '../modules/industries/IndustriesPage'
import {ApplicationsWrapper} from '../modules/applications/ApplicationsPage'
import {ProductsWrapper} from '../modules/products/ProductsPage'
import {AddProduct} from '../modules/products/Add/Add'
import {EditProduct} from '../modules/products/Edit/Edit'
import {ProjectsWrapper} from '../modules/projects/ProjectsPage'
import {SpecficationsWrapper} from '../modules/productSpecfications/SpecficationsPage'
import {Images} from '../modules/projects/Images/Images'
import {AboutBenyaWrapper} from '../modules/cms/AboutBenyaPage'
import {ChairmanMessageWrapper} from '../modules/cms/ChairmanMessagePage'
import {ContactWrapper} from '../modules/cms/ContactPage'
import { CarrersWrapper } from '../modules/cms/CareerPage'
import {FactoryWrapper} from '../modules/cms/FactoryPage'
import {HomeWrapper} from '../modules/cms/HomePage/HomePage'
import {ManagementTeamsWrapper} from '../modules/cms/ManagementTeams/ManagementTeamsPage'
import {PartnersWrapper} from '../modules/cms/Partners/PartnersPage'
import {SeoWrapper} from '../modules/seo/SeoPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { GetaquoteWrapper } from '../modules/cms/GetaquotePage'
import { ContactFormWrapper } from '../modules/cms/ContactFormPage'
import { CareerFormWrapper } from '../modules/cms/CareerFormPage'
import { GetQuotationWrapper } from '../modules/cms/GetQuotationFormPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='subsidiaries' element={<SubsidiariesWrapper />} />
        <Route path='newsroom' element={<NewsroomWrapper />} />
        <Route path='industries' element={<IndustriesWrapper />} />
        <Route path='applications' element={<ApplicationsWrapper />} />
        <Route path='products' element={<ProductsWrapper />} />
        <Route path='product-specfications' element={<SpecficationsWrapper />} />
        <Route path='add' element={<AddProduct />} />
        <Route path='edit/:id' element={<EditProduct />} />
        <Route path='images/:id' element={<Images />} />
        <Route path='seo' element={<SeoWrapper />} />
        <Route path='projects' element={<ProjectsWrapper />} />
        <Route path='homepage' element={<HomeWrapper />} />
        <Route path='about_benya' element={<AboutBenyaWrapper />} />
        <Route path='our_partners' element={<PartnersWrapper />} />
        <Route path='chairman_message' element={<ChairmanMessageWrapper />} />
        <Route path='management_teams' element={<ManagementTeamsWrapper />} />
        <Route path='our_factory' element={<FactoryWrapper />} />
        <Route path='contact_us' element={<ContactWrapper />} />
        <Route path='careers_page' element={<CarrersWrapper />} />
        <Route path='Get_a_quote' element={<GetaquoteWrapper />} />
        <Route path='contact_form' element={<ContactFormWrapper />} />
        <Route path='get_quotation' element={<GetQuotationWrapper />} />
        <Route path='career_form' element={<CareerFormWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/apps/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
