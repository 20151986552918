import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const AboutBenyaPage = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=about_benya&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=about_benya'
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [selectedImage2, setSelectedImage2] = useState<File | null>(null)

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data)
      // Set initial values when data is available
      formik.setValues({
        title_en: response.data.data.about_benya.title_en || '',
        title_ar: response.data.data.about_benya.title_ar || '',
        description_en: response.data.data.about_benya.description_en || '',
        description_ar: response.data.data.about_benya.description_ar || '',
        image1: response.data.data.about_benya.image1 || null,
        subtitle_en: response.data.data.about_benya.subtitle_en || '',
        subtitle_ar: response.data.data.about_benya.subtitle_ar || '',
        subtitle2_en: response.data.data.about_benya.subtitle2_en || '',
        subtitle2_ar: response.data.data.about_benya.subtitle2_ar || '',
        title2_en: response.data.data.about_benya.title2_en || '',
        title2_ar: response.data.data.about_benya.title2_ar || '',
        title_mission_en: response.data.data.about_benya.title_mission_en || '',
        title_mission_ar: response.data.data.about_benya.title_mission_ar || '',
        description_mission_en: response.data.data.about_benya.description_mission_en || '',
        description_mission_ar: response.data.data.about_benya.description_mission_ar || '',
        title_vision_en: response.data.data.about_benya.title_vision_en || '',
        title_vision_ar: response.data.data.about_benya.title_vision_ar || '',
        description_vision_en: response.data.data.about_benya.description_vision_en || '',
        description_vision_ar: response.data.data.about_benya.description_vision_ar || '',
        image2: response.data.data.about_benya.image2 || null,
        section_visibility_overview: response.data.data.about_benya.section_visibility_overview || '',
        section_visibility_get_to_know_us:response.data.data.about_benya.section_visibility_get_to_know_us || '',
        page_name: 'about_benya',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'about_benya',
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      image1: null,
      subtitle_en: '',
      subtitle_ar: '',
      subtitle2_en: '',
      subtitle2_ar: '',
      title2_en: '',
      title2_ar: '',
      title_mission_en: '',
      title_mission_ar: '',
      description_mission_en: '',
      description_mission_ar: '',
      title_vision_en: '',
      title_vision_ar: '',
      description_vision_en: '',
      description_vision_ar: '',
      image2: null,
      section_visibility_overview:'',
      section_visibility_get_to_know_us:'',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile2 = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile2) {
      setSelectedImage2(selectedFile2)
      formik.setFieldValue('image2', selectedFile2)
    }
  }


  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image1', selectedFile)
    }
  }

  const sectionVisibility = formik.values.section_visibility_overview === "1";
  const handleSectionVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const value = checked ? "1" : "0";
    formik.setFieldValue(name, value);
  };
  const sectionVisibility2 = formik.values.section_visibility_get_to_know_us === "1";
  const handleSectionVisibilityChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const value = checked ? "1" : "0";
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Overview section</span>
                    </h3>
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle_en')}
                      className='form-control'
                      name='subtitle_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle_ar')}
                      className='form-control'
                      name='subtitle_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  {data && data.about_benya && (
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange}
                        className='form-control'
                        name='image1'
                      />
                      <a
                        href={
                          data &&
                          data.about_benya &&
                          `${API_URL}/${
                            selectedImage ? selectedImage.name : data.about_benya.image1
                          }`
                        }
                        target='_blank'
                      >
                        <img
                          src={
                            data &&
                            data.about_benya &&
                            `${
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : `${API_URL}/${data.about_benya.image1}`
                            }`
                          }
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                  )}


              <div className='col-md-6 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                <br />
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    id='section_visibility_overview'
                    name='section_visibility_overview'
                    onChange={handleSectionVisibilityChange}
                    onBlur={formik.handleBlur}
                    checked={sectionVisibility}
                  />
                  <label className='form-check-label'></label>
                </div>
              </div>


                  <hr className='mt-10'/>
                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Get to know us section</span>
                    </h3>
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title2_en')}
                      className='form-control'
                      name='title2_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title2_ar')}
                      className='form-control'
                      name='title2_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle2_en')}
                      className='form-control'
                      name='subtitle2_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle2_ar')}
                      className='form-control'
                      name='subtitle2_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Mission Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_mission_en')}
                      className='form-control'
                      name='title_mission_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Mission Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_mission_ar')}
                      className='form-control'
                      name='title_mission_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Mission Description English</label>
                    <ReactQuill
                      value={formik.values.description_mission_en}
                      onChange={(value) => formik.setFieldValue('description_mission_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Mission Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_mission_ar}
                      onChange={(value) => formik.setFieldValue('description_mission_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Vision Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_vision_en')}
                      className='form-control'
                      name='title_vision_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Vision Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_vision_ar')}
                      className='form-control'
                      name='title_vision_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Vision Description English</label>
                    <ReactQuill
                      value={formik.values.description_vision_en}
                      onChange={(value) => formik.setFieldValue('description_vision_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Vision Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_vision_ar}
                      onChange={(value) => formik.setFieldValue('description_vision_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  {data && data.about_benya && (
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image 2</label>
                      <input
                        type='file'
                        onChange={handleImageChange2} // Handle image change
                        className='form-control'
                        name='image2' // Use a different name for this input field
                      />
                      <a
                        href={`${API_URL}/${
                          selectedImage2 ? selectedImage2.name : data.about_benya.image2
                        }`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage2
                              ? URL.createObjectURL(selectedImage2)
                              : `${API_URL}/${data.about_benya.image2}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                  )}
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_get_to_know_us'
                        name='section_visibility_get_to_know_us'
                        onChange={handleSectionVisibilityChange2}
                        onBlur={formik.handleBlur}
                        checked={sectionVisibility2}
                      />

                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 mt-10 text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

const AboutBenyaWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ABOUTBENYA'})}</PageTitle>
      <AboutBenyaPage />
    </>
  )
}

export {AboutBenyaWrapper}
