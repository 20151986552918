import {useState} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint, id}) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const [modal, setModal] = useState(false)

  const addSchema = Yup.object().shape({
    // initialValues: Yup.string().required('initialValues is required'),
    configuration: Yup.string().required('configuration is required'),
    // quantity_fiber: Yup.string().required('quantity_fiber is required'),
    // quantity_total_elements: Yup.string().required('quantity_total_elements is required'),
    // fiber_type_smf: Yup.string().required('fiber_type_smf is required'),
    // fiber_type_od: Yup.string().required('fiber_type_od is required'),
    nominal: Yup.string().required('nominal is required'),
    nominal_weight: Yup.string().required('nominal_weight is required'),
    max_allowed: Yup.string().required('max_allowed is required'),
  })

  const formik = useFormik({
    validationSchema: addSchema,
    initialValues: {
      configuration: '',
      quantity_fiber: '',
      quantity_total_elements: '',
      fiber_type_smf: '',
      fiber_type_od: '',
      nominal: '',
      nominal_weight: '',
      max_allowed: '',
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, value]) => value !== '')
      )
      try {
        await postRequest(endpoint, filteredValues)
        setChange(!change)
        resetForm()
        setModal(false)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_add_Design')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Design' aria-hidden='true' role='dialog'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Design Data</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              {/* Form fields */}
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Configuration</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Configuration'
                      {...formik.getFieldProps('configuration')}
                      className='form-control'
                      name='configuration'
                    />
                    {formik.touched.configuration && formik.errors.configuration && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.configuration}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Max Allowed</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Max Allowed'
                      {...formik.getFieldProps('max_allowed')}
                      className='form-control'
                      name='max_allowed'
                    />
                    {formik.touched.max_allowed && formik.errors.max_allowed && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.max_allowed}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>No. of Fiber</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='No. of Fiber'
                      {...formik.getFieldProps('quantity_fiber')}
                      className='form-control'
                      name='quantity_fiber'
                    />
                    {formik.touched.quantity_fiber && formik.errors.quantity_fiber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.quantity_fiber}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Quantity Total Element</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Quantity total element'
                      {...formik.getFieldProps('quantity_total_elements')}
                      className='form-control'
                      name='quantity_total_elements'
                    />
                    {formik.touched.quantity_total_elements &&
                      formik.errors.quantity_total_elements && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.quantity_total_elements}</span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Fiber type smf</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Fiber type smf'
                      {...formik.getFieldProps('fiber_type_smf')}
                      className='form-control'
                      name='fiber_type_smf'
                    />
                    {formik.touched.fiber_type_smf && formik.errors.fiber_type_smf && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fiber_type_smf}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Fiber type od</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Fiber type od'
                      {...formik.getFieldProps('fiber_type_od')}
                      className='form-control'
                      name='fiber_type_od'
                    />
                    {formik.touched.fiber_type_od && formik.errors.fiber_type_od && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fiber_type_od}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Nominal</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Nominal'
                      {...formik.getFieldProps('nominal')}
                      className='form-control'
                      name='nominal'
                    />
                    {formik.touched.nominal && formik.errors.nominal && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.nominal}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Nominal Weight</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Nominal Weight'
                      {...formik.getFieldProps('nominal_weight')}
                      className='form-control'
                      name='nominal_weight'
                    />
                    {formik.touched.nominal_weight && formik.errors.nominal_weight && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.nominal_weight}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* End of form fields */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
