/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import {AsyncPaginate} from 'react-select-async-paginate'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [selectedOption, setSelectedOption] = useState<any>()
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}?subsidiary`).then((response) => {
        setData(response.data.data)

        const initialSubsidiary = {
          label: response.data.data.subsidiary.title,
          value: response.data.data.subsidiary_id,
        }

        setSelectedOption(initialSubsidiary)

        formik.setValues({
          title_en: response.data.data.title_en || '',
          title_ar: response.data.data.title_ar || '',
          description_en: response.data.data.description_en || '',
          description_ar: response.data.data.description_ar || '',
          subsidiary_id: initialSubsidiary.value || '',
          is_popular: response.data.data.is_popular,
          image: null,
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const loadOptions = async (searchQuery: string, loadedOptions: any) => {
    const response = await axios.get(`${API_URL}/v1/cables/admin/subsidiaries`, {
      params: {query: searchQuery},
    })

    const options = response.data.data.data.map((item: any) => ({
      value: item.id,
      label: item.title,
    }))

    return {
      options,
      hasMore: false,
      additional: {
        page: 1,
      },
    }
  }

  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      subsidiary_id: '',
      image: null,
      is_popular: false,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editRequest(selectedRowId, endpoint, values)
        setChange(!change)
        setModal(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        toast.success('Form submitted successfully')
        const modal = document.getElementById('kt_modal_edit_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Sub' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit {data.title_en}</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Name English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Title English'
                        {...formik.getFieldProps('title_en')}
                        className='form-control'
                        name='title_en'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Name Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Title Arabic'
                        {...formik.getFieldProps('title_ar')}
                        className='form-control'
                        name='title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description English</label>
                      <ReactQuill
                        value={formik.values.description_en}
                        onChange={(value) => formik.setFieldValue('description_en', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description English'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description Arabic</label>
                      <ReactQuill
                        value={formik.values.description_ar}
                        onChange={(value) => formik.setFieldValue('description_ar', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description Arabic'
                      />
                    </div>
                    <div className='col-md-6  mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Subsidiary</label>
                      <AsyncPaginate
                        loadOptions={loadOptions}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={selectedOption}
                        onChange={(option) => {
                          setSelectedOption(option)
                          formik.setFieldValue('subsidiary_id', option ? option.value : '')
                        }}
                        inputValue={inputValue}
                        onInputChange={(value) => setInputValue(value)}
                        isClearable
                      />
                    </div>
                    <div className='col-md-6  mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Is Popular ? </label>
                      <br />
                      <div className='form-check form-check-solid form-switch fv-row'>
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          // name="is_popular"
                          {...formik.getFieldProps('is_popular')}
                          checked={formik.values.is_popular}
                        />
                        <label className='form-check-label'></label>
                      </div>
                    </div>
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange} // Handle image change
                        className='form-control'
                        name='image'
                        ref={fileInputRef}
                      />
                    </div>
                    <div className='col-md-12 mt-5 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
