import React, {useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {deleteAction, actionsDetails} from '../../../CrudActions/index'
import DataTableExample from '../../../apps/datatable/Datatable'
import {Add} from './Add'
import {Edit} from './Edit'

interface RowData {
  id: string
  product_series?: {
    value: string
  }
  product_type: {
    value: string
  }
}

export const Classifications = ({id}) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit = '#kt_modal_edit_clas'
  const endpoint = `/v1/cables/admin/product-classifications`

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Product Series',
      selector: (row) => (row.product_series ? row.product_series.value : ''), // Check if product_series is defined
      sortable: true,
    },
    {
      name: 'Product Type',
      selector: (row) => row.product_type.value,
      sortable: true,
    },
  ]

  return (
    <>
      <DataTableExample
        endpoint={`${endpoint}?product_id=${id}`}
        columns={dynamicColumns}
        cardTitle='Classifications'
        addButtonLabel='Add New Classifications'
        ModalAdd='#kt_modal_add_clas'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} id={id} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
        id={id}
      />
    </>
  )
}
