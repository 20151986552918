import React,{useState} from 'react';
import { TableColumn } from 'react-data-table-component';
import {deleteAction,actionsDetails} from "../../../CrudActions/index"
import DataTableExample from '../../../apps/datatable/Datatable';
import {Add} from './Add'
import {Edit} from './Edit'

interface RowData {
  id:string
  configuration: string;
  quantity_fiber: string;
  quantity_total_elements:number
  fiber_type_smf:string
  fiber_type_od: string;
  nominal: string;
  nominal_weight: string;
  max_allowed: string;
}

export const DesignData = ({ id }) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit ="#kt_modal_edit_Design"
  const endpoint=`/v1/cables/admin/products-design-data`

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )
  
  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Configuration',
      selector: (row) => row.configuration,
      sortable: true,
    },
    {
      name: 'No. of Fiber',
      selector: (row) => row.quantity_fiber,
      sortable: true,
    },
    {
      name: 'Nominal',
      selector: (row) => row.nominal,
      sortable: true,
    },
    {
      name: 'Nominal weight',
      selector: (row) => row.nominal_weight,
      sortable: true,
    },
    {
      name: 'Max allowed',
      selector: (row) => row.max_allowed,
      sortable: true,
    }
    
  ]

  return (
    <>
      <DataTableExample
      endpoint={`${endpoint}?product_id=${id}`}
      columns={dynamicColumns}
      cardTitle='Design Data'
      addButtonLabel='Add New Design Data'
      ModalAdd='#kt_modal_add_Design'
      actionsColumn={actionsColumn}
      change={change}
    />
    <Add change={change} setChange={setChange} endpoint={endpoint} id={id} />
    <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
        id={id}
      />
 </>
  );
};
