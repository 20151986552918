/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import axios from 'axios'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
        setData(response.data.data)
        // Set initial values when data is available
        formik.setValues({
          image: response.data.data.image || null,
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const formik = useFormik({
    initialValues: {
      image: null,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editRequest(selectedRowId, endpoint, values)
        setChange(!change)
        setModal(false)
        setSelectedImage(null)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
      } catch (error: any) {
        setLoading(false)
        setModal(true)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Sub' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit {data.title_en}</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange}
                        className='form-control'
                        name='image'
                        ref={fileInputRef}
                      />
                      <a
                        href={`${API_URL}/${selectedImage ? selectedImage.name : data.image}`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : `${API_URL}/${data.image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>

                    <div className='col-md-12 mt-10 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={modal ? '' : 'modal'}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
