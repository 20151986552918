/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuthenticatedRequest} from '../Components/Requests'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint}: any) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [selectedOption, setSelectedOption] = useState<any | null>(null)
  const [selectedOption2, setSelectedOption2] = useState<any | null>(null)
  const [selectedOption3, setSelectedOption3] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  const addSchema = Yup.object().shape({
    title_en: Yup.string().required('Title English is required'),
    title_ar: Yup.string().required('Title Arabic is required'),
    description_en: Yup.string().required('Description English is required'),
    description_ar: Yup.string().required('Description Arabic is required'),
    side_title_en: Yup.string().required('Sidetitle English is required'),
    side_title_ar: Yup.string().required('Sidetitle Arabic is required'),
    side_description_en: Yup.string().required('Side Description is required'),
    side_description_ar: Yup.string().required('Side Description is required'),
    overview_en: Yup.string().required('Overview is required'),
    overview_ar: Yup.string().required('Overview is required'),
    client_name_en: Yup.string().required('Client Name is required'),
    client_name_ar: Yup.string().required('Client Name is required'),
    product_id: Yup.array()
      .of(Yup.number().integer('Each Product ID must be an integer'))
      .required('Product is required'),
    // industry_id: Yup.array()
    //   .of(Yup.number().integer('Each industry ID must be an integer'))
    //   .required('Industry is required'),
    application_id: Yup.array()
      .of(Yup.number().integer('Each Application ID must be an integer'))
      .required('Application is required'),
    main_image: Yup.string().required('Image is required'),
  })

  const initialValues = {
    title_en: '',
    title_ar: '',
    side_title_en: '',
    side_title_ar: '',
    description_en: '',
    description_ar: '',
    side_description_en: '',
    side_description_ar: '',
    overview_en: '',
    overview_ar: '',
    client_name_en: '',
    client_name_ar: '',
    // industry_id: ['21'],
    application_id: '',
    product_id: '',
    project_date: '',
    main_image: '',
  }

  const loadOptions = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/industries`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  const loadOptions2 = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/applications`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  const loadOptions3 = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/products`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        // values.industry_id = ['21']
        await postRequest(endpoint, values)
        setSelectedOption(null)
        setSelectedOption2(null)
        setSelectedOption3(null)
        setChange(!change)
        resetForm()
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        setModal(false)
        toast.success('Form submitted successfully')
        // Close the modal
        const modal = document.getElementById('kt_modal_add_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        setModal(true)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Sub' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Project</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title English'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                    {formik.touched.title_en && formik.errors.title_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title Arabic'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                    {formik.touched.title_ar && formik.errors.title_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Description English'
                    />
                    {formik.touched.description_en && formik.errors.description_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Description English'
                    />
                    {formik.touched.description_ar && formik.errors.description_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Side Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='side Title English'
                      {...formik.getFieldProps('side_title_en')}
                      className='form-control'
                      name='side_title_en'
                    />
                    {formik.touched.side_title_en && formik.errors.side_title_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.side_title_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Side Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Side Title Arabic'
                      {...formik.getFieldProps('side_title_ar')}
                      className='form-control'
                      name='side_title_ar'
                    />
                    {formik.touched.side_title_ar && formik.errors.side_title_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.side_title_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>
                      Side Description English
                    </label>
                    <ReactQuill
                      value={formik.values.side_description_en}
                      onChange={(value) => formik.setFieldValue('side_description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Side Description English'
                    />
                    {formik.touched.side_description_en && formik.errors.side_description_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.side_description_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>
                      Side Description Arabic
                    </label>
                    <ReactQuill
                      value={formik.values.side_description_ar}
                      onChange={(value) => formik.setFieldValue('side_description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Side Description Arabic'
                    />
                    {formik.touched.side_description_ar && formik.errors.side_description_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.side_description_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Overview English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Overview English'
                      {...formik.getFieldProps('overview_en')}
                      className='form-control'
                      name='overview_en'
                    />
                    {formik.touched.overview_en && formik.errors.overview_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.overview_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Overview Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Overview Arabic'
                      {...formik.getFieldProps('overview_ar')}
                      className='form-control'
                      name='overview_ar'
                    />
                    {formik.touched.overview_ar && formik.errors.overview_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.overview_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Client Name English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Client Name English'
                      {...formik.getFieldProps('client_name_en')}
                      className='form-control'
                      name='client_name_en'
                    />
                    {formik.touched.client_name_en && formik.errors.client_name_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.client_name_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Client Name Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Client Name Arabic'
                      {...formik.getFieldProps('client_name_ar')}
                      className='form-control'
                      name='client_name_ar'
                    />
                    {formik.touched.client_name_ar && formik.errors.client_name_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.client_name_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Industries</label>
                    <AsyncPaginate
                      loadOptions={loadOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={selectedOption}
                      onChange={(options) => {
                        setSelectedOption(options)
                        const selectedValues = options.map((option) => option.value)
                        formik.setFieldValue('industry_id', selectedValues)
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                      isMulti
                    />

                    {formik.touched.industry_id && formik.errors.industry_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.industry_id}</span>
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Applications</label>
                    <AsyncPaginate
                      loadOptions={loadOptions2}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={selectedOption2}
                      onChange={(options) => {
                        setSelectedOption2(options)
                        const selectedValues = options.map((option) => option.value)
                        formik.setFieldValue('application_id', selectedValues)
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                      isMulti
                    />
                    {formik.touched.application_id && formik.errors.application_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.application_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Products</label>

                    <AsyncPaginate
                      loadOptions={loadOptions3}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      value={selectedOption3}
                      onChange={(options) => {
                        setSelectedOption3(options)
                        const selectedValues = options.map((option) => option.value)
                        formik.setFieldValue('product_id', selectedValues)
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                      isMulti
                    />
                    {formik.touched.product_id && formik.errors.product_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.product_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Project Date</label>
                    <input
                      type='date'
                      className='form-control'
                      autoComplete='off'
                      placeholder='Date'
                      {...formik.getFieldProps('project_date')}
                    />
                  </div>
                  <div className='col-md-12  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Main Image</label>
                    <input
                      type='file'
                      onChange={(event) => {
                        const selectedFile =
                          event.currentTarget.files && event.currentTarget.files[0]
                        if (selectedFile) {
                          formik.setFieldValue('main_image', selectedFile)
                        }
                      }}
                      className='form-control'
                      name='main_image'
                      ref={fileInputRef}
                    />
                    {formik.touched.main_image && formik.errors.main_image && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.main_image}</span>
                        </div>
                      </div>
                    )}
                    <p>
                      Recommended size: 415x235
                      <br />
                      Aspect ratio 16:9
                    </p>
                  </div>

                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                    <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                      <span className='indicator-label'>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
