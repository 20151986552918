import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import axios from 'axios'
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId, id}) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState(false)

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
      setData(response.data.data)
      formik.setValues({
        configuration: response.data.data.configuration || '',
        quantity_fiber: response.data.data.quantity_fiber || '',
        quantity_total_elements: response.data.data.quantity_total_elements || '',
        fiber_type_smf: response.data.data.fiber_type_smf || '',
        fiber_type_od: response.data.data.fiber_type_od || '',
        nominal: response.data.data.nominal || '',
        nominal_weight: response.data.data.nominal_weight || '',
        max_allowed: response.data.data.max_allowed || '',
        product_id: id,
      })
    })
  }, [API_URL, endpoint, selectedRowId])

  const formik = useFormik({
    initialValues: {
      configuration: '',
      quantity_fiber: '',
      quantity_total_elements: '',
      fiber_type_smf: '',
      fiber_type_od: '',
      nominal: '',
      nominal_weight: '',
      max_allowed: '',
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, value]) => value !== '')
      )
      try {
        await editRequest(selectedRowId, endpoint, filteredValues)
        setChange(!change)
        setModal(false)
        setLoading(false)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_edit_Design')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        setModal(true)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Design' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                {/* Form fields */}
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Configuration</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Configuration'
                        {...formik.getFieldProps('configuration')}
                        className='form-control'
                        name='configuration'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Max Allowed</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Max Allowed'
                        {...formik.getFieldProps('max_allowed')}
                        className='form-control'
                        name='max_allowed'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>No. of Fiber</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='No. of Fiber'
                        {...formik.getFieldProps('quantity_fiber')}
                        className='form-control'
                        name='quantity_fiber'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Quantity Total Element</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Quantity total element'
                        {...formik.getFieldProps('quantity_total_elements')}
                        className='form-control'
                        name='quantity_total_elements'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Fiber type smf</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Fiber type smf'
                        {...formik.getFieldProps('fiber_type_smf')}
                        className='form-control'
                        name='fiber_type_smf'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Fiber type od</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Fiber type od'
                        {...formik.getFieldProps('fiber_type_od')}
                        className='form-control'
                        name='fiber_type_od'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Nominal</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Nominal'
                        {...formik.getFieldProps('nominal')}
                        className='form-control'
                        name='nominal'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Nominal Weight</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Nominal Weight'
                        {...formik.getFieldProps('nominal_weight')}
                        className='form-control'
                        name='nominal_weight'
                      />
                    </div>
                    <div className='col-md-12 mt-5 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of form fields */}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
