import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import clsx from 'clsx'
import { PartnersContentPage } from './PartnersContentPage'
import { Logos } from './Logos/Logos'

const PartnersPage = () => {
  const [tab, setTab] = useState('PartnersPage')

  return (
    <>
        <div className='card card-custom'>
          <div className='card-header card-header-stretch overflow-auto'>
            <ul
              className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
              role='tablist'
            >
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'PartnersPage',
                  })}
                  onClick={() => setTab('PartnersPage')}
                  role='tab'
                >
                  Page Content
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'Logos',
                  })}
                  onClick={() => setTab('Logos')}
                  role='tab'
                >
                  Logos
                </a>
              </li>
            </ul>
          </div>
        {/* end::Header */}

          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', {active: tab === 'PartnersPage'})}>
                <PartnersContentPage />
              </div>
              <div className={clsx('tab-pane', {active: tab === 'Logos'})}>
                <Logos />
              </div>
            </div>
          </div>
        
      </div>
    </>
  )
}

const PartnersWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OURPARTNERS'})}</PageTitle>
      <PartnersPage />
    </>
  )
}

export {PartnersWrapper}

