import React, {useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {deleteAction, actionsDetails} from '../../CrudActions/index'
import DataTableExample from '../../apps/datatable/Datatable'
import {Add} from './Add'
import {Edit} from './Edit'
import {useParams} from 'react-router-dom'

interface RowData {
  id: string
  image: string
  status:string
}

export const Images = () => {

  const {id} = useParams()
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit = '#kt_modal_edit_Img'
  const endpoint = `/v1/cables/admin/project-image`
  const API_URL = process.env.REACT_APP_API_URL || ''

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Image',
      selector: (row) => row.image,
      cell: (row) => (
        <img src={`${API_URL}/${row.image}`} style={{height:"100px",width:"100px"}}/>
      ),
    },
    {
      name: 'Image',
      selector: (row) => row.image,
      cell: (row) => (
        <a href={`${API_URL}/${row.image}`} target='_blank'>
          Preivew Image
        </a>
      ),

      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 'approved' ? 'badge-light-primary' : 'badge-light-warning'
          } fs-7 fw-semibold`}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
  ]
  return (
    <>
      <DataTableExample
        endpoint={`${endpoint}?project_id=${id}`}
        columns={dynamicColumns}
        cardTitle='Images'
        addButtonLabel='Add New Image'
        ModalAdd='#kt_modal_add_Img'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} id={id} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
        id={id}
      />
    </>
  )
}
