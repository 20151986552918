import React,{useState} from 'react';
import { TableColumn } from 'react-data-table-component';
import {deleteAction,actionsDetails} from "../../../CrudActions/index"
import DataTableExample from '../../../apps/datatable/Datatable';
import {Add} from './Add'
import {Edit} from './Edit'

interface RowData {
  id:string
  test_item: string;
  test_item_en: string;
  test_item_ar:number
  test_method:string
  specified_value: string;
  requirements: string;
}

export const OpticalData = ({ id }) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit ="#kt_modal_edit_Opt"
  const endpoint=`/v1/cables/admin/products-optical-data`

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )
  
  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Test Item',
      selector: (row) => row.test_item,
      sortable: true,
    },
    {
      name: 'Test Item English',
      selector: (row) => row.test_item_en,
      sortable: true,
    },
    {
      name: 'Test Item Arabic',
      selector: (row) => row.test_item_ar,
      sortable: true,
    }
    ,
    {
      name: 'Test Method',
      selector: (row) => row.test_method,
      sortable: true,
    },
    {
      name: 'Specified Value',
      selector: (row) => row.specified_value,
      sortable: true,
    },
    {
      name: 'requirements',
      selector: (row) => row.requirements,
      sortable: true,
    }
    
  ]

  return (
    <>
      <DataTableExample
      endpoint={`${endpoint}?product_id=${id}`}
      columns={dynamicColumns}
      cardTitle='Optical Data'
      addButtonLabel='Add New Optical Data'
      ModalAdd='#kt_modal_add_Optical'
      actionsColumn={actionsColumn}
      change={change}
    />
    <Add change={change} setChange={setChange} endpoint={endpoint} id={id} />
    <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
        id={id}
      />
 </>
  );
};
