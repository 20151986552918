import React, {useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {deleteAction, actionsDetails} from '../../../CrudActions/index'
import DataTableExample from '../../../apps/datatable/Datatable'
import {Add} from './Add'
import {Edit} from './Edit'

interface RowData {
  id: string
  title_en: string
  title_ar: string
  url: string
  file: string
}

export const Documents = ({id}) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit = '#kt_modal_edit_Doc'
  const endpoint = `/v1/cables/admin/products-documents-data`
  const API_URL = process.env.REACT_APP_API_URL || ''

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Title English',
      selector: (row) => row.title_en,
      sortable: true,
    },
    {
      name: 'Title Arabic',
      selector: (row) => row.title_ar,
      sortable: true,
    },
    {
      name: 'URL',
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: 'File',
      selector: (row) => row.file,
      cell: (row) => (
        <a href={`${API_URL}/${row.file}`} target='_blank'>
          Preivew File
        </a>
      ),

      sortable: true,
    },
  ]

  return (
    <>
      <DataTableExample
        endpoint={`${endpoint}?product_id=${id}`}
        columns={dynamicColumns}
        cardTitle='Documents'
        addButtonLabel='Add New Document'
        ModalAdd='#kt_modal_add_Doc'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} id={id} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
        id={id}
      />
    </>
  )
}
