/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../Components/Requests'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import {toast} from 'react-toastify'

export const MainData = ({id}) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/products'
  const [selectedOption, setSelectedOption] = useState<{label: any; value: any}[]>([])
  const [selectedOption2, setSelectedOption2] = useState<{label: any; value: any}[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, setSelectedImage] = useState<File | null>(null)

  const loadOptions = async (searchQuery: string) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/industries`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  const loadOptions2 = async (searchQuery: string) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/applications`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}/${id}`).then((response) => {
      setData(response.data.data)

      const initialIndustry = response.data.data.industries.map((industry) => ({
        label: industry.title,
        value: industry.id,
      }))
      setSelectedOption(initialIndustry)

      const initialApplication = response.data.data.applications.map((application) => ({
        label: application.title,
        value: application.id,
      }))
      setSelectedOption2(initialApplication)

      formik.setValues({
        title_en: response.data.data.title_en || '',
        title_ar: response.data.data.title_ar || '',
        description_en: response.data.data.description_en || '',
        description_ar: response.data.data.description_ar || '',
        features_en: response.data.data.features_en || '',
        features_ar: response.data.data.features_ar || '',
        is_featured: response.data.data.is_featured.toString(),
        industry_id: initialIndustry.map((option) => option.value), // Set industry_id to default value 21
        application_id: initialApplication.map((option) => option.value),
        main_image: null,
        catalog: null,
      })
    })
  }, [API_URL, endpoint, id])


  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      features_en: '',
      features_ar: '',
      is_featured: '',
      industry_id: '',
      application_id: '',
      main_image: null,
      catalog: null,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editRequest(id, endpoint, values)
        setLoading(false)
        toast.success('Form submitted successfully')
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('main_image', selectedFile)
    }
  }

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <label className=' fs-5 fw-semibold mb-2'>Title English</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Enter Title English'
                {...formik.getFieldProps('title_en')}
                className='form-control'
                name='title_en'
              />
            </div>
            <div className='col-md-6'>
              <label className=' fs-5 fw-semibold mb-2'>Title Arabic</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Enter Title Arabic'
                {...formik.getFieldProps('title_ar')}
                className='form-control'
                name='title_ar'
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Description English</label>
              <ReactQuill
                value={formik.values.description_en}
                onChange={(value) => formik.setFieldValue('description_en', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Description English'
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Description Arabic</label>
              <ReactQuill
                value={formik.values.description_ar}
                onChange={(value) => formik.setFieldValue('description_ar', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Description Arabic'
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Features English</label>
              <ReactQuill
                value={formik.values.features_en}
                onChange={(value) => formik.setFieldValue('features_en', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Features English'
              />
            </div>
            <div className='col-md-6 mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Features Arabic</label>
              <ReactQuill
                value={formik.values.features_ar}
                onChange={(value) => formik.setFieldValue('features_ar', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Features Arabic'
              />
            </div>
            {/* <div className='col-md-6  mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Industries</label>
               <AsyncPaginate
                  loadOptions={loadOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={selectedOption} 
                  onChange={(options) => {
                    setSelectedOption([...options])
                    const selectedValues = options.map((option) => option.value);
                    formik.setFieldValue('industry_id', selectedValues);
                  }}
                  inputValue={inputValue}
                  onInputChange={(value) => setInputValue(value)}
                  isClearable
                  isMulti
              />
            </div> */}
            <div className='col-md-6  mt-5'>
              <label className=' fs-5 fw-semibold mb-2'>Applications</label>
              <AsyncPaginate
                loadOptions={loadOptions2}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={selectedOption2}
                onChange={(options) => {
                  setSelectedOption2([...options])
                  const selectedValues = options.map((option) => option.value)
                  formik.setFieldValue('application_id', selectedValues)
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
                isMulti
              />
            </div>
            <div className='col-md-6  mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Is Featured ? </label>
              <br />
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  checked={formik.values.is_featured === 'true'}
                  onChange={(e) => {
                    const newValue = e.target.checked ? 'true' : 'false'
                    formik.setFieldValue('is_featured', newValue)
                  }}
                />
                <label className='form-check-label'></label>
              </div>
            </div>

            <div className='col-md-12 mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Image</label>
              <input
                type='file'
                onChange={handleImageChange}
                className='form-control'
                name='main_image'
                ref={fileInputRef}
              />
            </div>
            <div className='col-md-12  mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Catalog</label>
              <input
                type='file'
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
                  if (selectedFile) {
                    formik.setFieldValue('catalog', selectedFile)
                  }
                }}
                className='form-control'
                name='catalog'
              />
            </div>
            <div className='col-md-12 mt-5 text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
