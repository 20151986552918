/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint}: any) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const {postRequest} = useAuthenticatedRequest()


  const initialValues = {
    page_name:'',
    title: '',
    meta_description: '',
    keywords: '',
    header_content: '',
    image_alt_text: '',
    canonical_url: '',
    robots_meta: '',
    schema_markup: '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await postRequest(endpoint, values)
        setModal(false)
        setChange(!change)
        resetForm()
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_add_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Sub' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New SEO</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='fs-5 fw-semibold mb-2'>Page Name</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Page Name'
                      {...formik.getFieldProps('page_name')}
                      className='form-control'
                      name='page_name'
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className='fs-5 fw-semibold mb-2'>Title</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Title'
                      {...formik.getFieldProps('title')}
                      className='form-control'
                      name='title'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Meta Description</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Meta Description'
                      {...formik.getFieldProps('meta_description')}
                      className='form-control'
                      name='meta_description'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Keywords</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Keywords'
                      {...formik.getFieldProps('keywords')}
                      className='form-control'
                      name='keywords'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Header Content</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Header Content'
                      {...formik.getFieldProps('header_content')}
                      className='form-control'
                      name='header_content'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Image Alt Text</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Image Alt Text'
                      {...formik.getFieldProps('image_alt_text')}
                      className='form-control'
                      name='image_alt_text'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Canonical url</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Canonical url'
                      {...formik.getFieldProps('canonical_url')}
                      className='form-control'
                      name='canonical_url'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Robots Meta</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Robots Meta'
                      {...formik.getFieldProps('robots_meta')}
                      className='form-control'
                      name='robots_meta'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Schema Markup</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Schema Markup'
                      {...formik.getFieldProps('schema_markup')}
                      className='form-control'
                      name='schema_markup'
                    />
                  </div>
                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                      <span className='indicator-label'>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
