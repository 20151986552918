/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

interface RowData {
  id: number
  name: string
  email: string
  phone_number: string
  job_title: string
  cover_letter: string
  cv: string
}

type Props = {
  className: string
  data: RowData[]
}

const TablesWidgetCareer: React.FC<Props> = ({className, data}) => {
  const API_URL = process.env.REACT_APP_API_URL || ''

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th style={{width: '2%'}}>ID</th>
                <th style={{width: '15%'}}>Name</th>
                <th style={{width: '15%'}}>Email</th>
                <th style={{width: '10%'}}>Phone Number</th>
                <th style={{width: '10%'}}>Job Title</th>
                <th style={{width: '20%'}}>Cover Letter</th>
                <th style={{width: '20%'}}>CV File</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td style={{width: '2%'}}>{row.id}</td>
                  <td style={{width: '5%'}}>{row.name}</td>
                  <td style={{width: '15%'}}>{row.email}</td>
                  <td style={{width: '10%'}}>{row.phone_number}</td>
                  <td style={{width: '15%'}}>{row.job_title}</td>
                  <td style={{width: '25%'}}>{row.cover_letter}</td>
                  <td style={{width: '20%'}}>
                    <a href={`${API_URL}/${row.cv}`} target='_blank'>
                      Preivew File
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export type {RowData} // Use 'export type' to re-export the type
export {TablesWidgetCareer}
