import axios from 'axios'
import {UserModel,AuthModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/v1/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const GET_USER = `${API_URL}/Profile`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGOUT_URL = `${API_URL}/v1/auth/logout`;

export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestLogout(refreshToken) {
  return axios.post(LOGOUT_URL, {
    refreshToken: refreshToken
  });
}

export function getUserByToken(token: string, refreshToken: string) {
  if (!refreshToken) {
    throw new Error("Refresh token is not available");
  }
  const GET_USER_BY_ACCESSTOKEN_URL = refreshToken;
}





