import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const CareerPage = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=careers_page&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=careers_page'

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data.careers_page)
      // Set initial values when data is available
      formik.setValues({
        title_en: response.data.data.careers_page.title_en || '',
        title_ar: response.data.data.careers_page.title_ar || '',
        description_en: response.data.data.careers_page.description_en || '',
        description_ar: response.data.data.careers_page.description_ar || '',
        page_name: 'careers_page',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'careers_page',
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                  </div>
                  
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  
                
               
                  <div className='col-md-12 mt-10 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

const CarrersWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CAREERS'})}</PageTitle>
      <CareerPage />
    </>
  )
}

export {CarrersWrapper}
