import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import DataTableExample from '../apps/datatable/Datatable'
import {TableColumn} from 'react-data-table-component'
import {Add} from './Add'
import {Edit} from './Edit'
import {deleteAction, actionsDetails} from '../CrudActions/index'

interface RowData {
  id: number
  title_en: string
  title_ar: string
  status: string
}

const SubsidiariesPage = () => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const endpoint = '/v1/cables/admin/subsidiaries'
  const ModalEdit = '#kt_modal_edit_Sub'
  const rowID = selectedRowId
  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId 
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name English',
      selector: (row) => row.title_en,
      sortable: true,
    },
    {
      name: 'Name Arabic',
      selector: (row) => row.title_ar,
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 'approved' ? 'badge-light-primary' : 'badge-light-warning'
          } fs-7 fw-semibold`}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    
  ]
  return (
    <>
      <DataTableExample
        endpoint={endpoint}
        columns={dynamicColumns}
        cardTitle='Subsidiaries'
        addButtonLabel='Add New Subsidiary'
        ModalAdd='#kt_modal_add_Sub'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
      />
    </>
  )
}

const SubsidiariesWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.Subsidiaries'})}</PageTitle>
      <SubsidiariesPage />
    </>
  )
}

export {SubsidiariesWrapper}
