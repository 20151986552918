/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import { MainData } from './MainData'
import { PageTitle } from '../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';


export const AddProduct = ()=>{

    const [tab, setTab] = useState('MainData')
    const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.Products'})}</PageTitle>
      <div className='card card-custom'>
          <div className='card-header card-header-stretch overflow-auto'>
            <ul
              className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
              role='tablist'
            >
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'MainData',
                  })}
                  onClick={() => setTab('MainData')}
                  role='tab'
                >
                  Main Data
                </a>
              </li>
            </ul>
          </div>
        {/* end::Header */}

          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', {active: tab === 'MainData'})}>
                <MainData />
              </div>
            </div>
          </div>
        
      </div>

    </>
 
  )
}
