import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useAuthenticatedRequest } from '../../Components/Requests';
import axios from 'axios';
import { toast } from 'react-toastify';

export const VisibilitySections = () => {
  const [loading, setLoading] = useState(false);
  const { editCmsRequests } = useAuthenticatedRequest();
  const API_URL = process.env.REACT_APP_API_URL || '';
  const [data, setData] = useState<any>({});
  const endpoint = '/v1/cables/admin/cms?page_name=section_visibility&mode=admin';
  const url = '/v1/cables/admin/cms?page_name=section_visibility';

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data.section_visibility);
      const initialValues: any = {
        page_name: 'section_visibility',
      };
      const sections = [
        { name: 'home_slider', label: 'Slider', defaultValue: '' },
        { name: 'home_products', label: 'Featured Products', defaultValue: '' },
        { name: 'home_why_benya_cables', label: 'Why Benya Cables', defaultValue: '' },
        { name: 'home_lastest_projects', label: 'Lastest Projects', defaultValue: '' },
        { name: 'home_lastest_news', label: 'Lastest News', defaultValue: '' },
      ];

      sections.forEach((section) => {
        initialValues[section.name] = response.data.data.section_visibility[section.name] || section.defaultValue;
      });

      formik.setValues(initialValues);
    });
  }, [API_URL, endpoint]);
 
  const formik = useFormik({
    initialValues: {
      page_name: 'section_visibility',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        await editCmsRequests(url, values);
        setLoading(false);
        toast.success('Form submitted successfully');
      } catch (error: any) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred:', error.response.data.message);
        }
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handleSectionVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const value = checked ? '1' : '0';
    formik.setFieldValue(name, value);
  };

  const sections = [
    { name: 'home_slider', label: 'Slider' },
    { name: 'home_products', label: 'Featured Products' },
    { name: 'home_why_benya_cables', label: 'Why Benya Cables' },
    { name: 'home_lastest_projects', label: 'Lastest Projects' },
    { name: 'home_lastest_news', label: 'Lastest News' },

  ];

  return (
    <>
      <div className='row mb-10'>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
          <div className='container'>
            <div className='row'>
              {sections.map((section) => (
                <div key={section.name} className='col-md-12 mt-5 d-flex'>
                  <label className='fs-5 fw-semibold mb-2'>{section.label}</label>
                  <div className='form-check form-check-solid form-switch fv-row ms-4'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id={section.name}
                      name={section.name}
                      onChange={handleSectionVisibilityChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values[section.name] === '1'}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              ))}

              <div className='col-md-12 mt-10 text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
