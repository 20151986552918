import {useState} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint, id}) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const [modal, setModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      test_item_en: '',
      test_item_ar: '',
      test_method: '',
      specified_value: '',
      requirements: '',
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await postRequest(endpoint, values)
        setChange(!change)
        resetForm()
        setModal(false)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_add_Optical')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setModal(true)
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Optical' aria-hidden='true' role='dialog'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Optical Data</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              {/* Form fields */}
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className=' fs-5 fw-semibold mb-2'>Test Item English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Test Item English'
                      {...formik.getFieldProps('test_item_en')}
                      className='form-control'
                      name='test_item_en'
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className=' fs-5 fw-semibold mb-2'>Test Item Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Test Item Arabic'
                      {...formik.getFieldProps('test_item_ar')}
                      className='form-control'
                      name='test_item_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Test Method</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Test Method'
                      {...formik.getFieldProps('test_method')}
                      className='form-control'
                      name='test_method'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Specified Value</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Specified Value'
                      {...formik.getFieldProps('specified_value')}
                      className='form-control'
                      name='specified_value'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>Requirements</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Requirements'
                      {...formik.getFieldProps('requirements')}
                      className='form-control'
                      name='requirements'
                    />
                  </div>

                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* End of form fields */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
