/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [selectedOption, setSelectedOption] = useState<any | null>(null)
  const [selectedOption2, setSelectedOption2] = useState<any | null>(null)
  const [selectedOption3, setSelectedOption3] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)
  useEffect(() => {
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
        setData(response.data.data)

        const initialIndustry = response.data.data.industries.map((industry) => ({
          label: industry.title,
          value: industry.id,
        }))
        setSelectedOption(initialIndustry)

        const initialApplication = response.data.data.applications.map((application) => ({
          label: application.title,
          value: application.id,
        }))
        setSelectedOption2(initialApplication)

        const initialProducts = response.data.data.products.map((product) => ({
          label: product.title,
          value: product.id,
        }))

        setSelectedOption3(initialProducts)

        formik.setValues({
          title_en: response.data.data.title_en || '',
          title_ar: response.data.data.title_ar || '',
          side_title_en: response.data.data.side_title_en || '',
          side_title_ar: response.data.data.side_title_ar || '',
          description_en: response.data.data.description_en || '',
          description_ar: response.data.data.description_ar || '',
          side_description_en: response.data.data.side_description_en || '',
          side_description_ar: response.data.data.side_description_ar || '',
          overview_en: response.data.data.overview_en || '',
          overview_ar: response.data.data.overview_ar || '',
          client_name_en: response.data.data.client_name_en || '',
          client_name_ar: response.data.data.client_name_ar || '',
          industry_id: initialIndustry.map((option) => option.value),
          application_id: initialApplication.map((option) => option.value),
          product_id: initialProducts.map((option) => option.value),
          project_date: response.data.data.project_date || '',
          main_image: null,
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const loadOptions = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/industries`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  const loadOptions2 = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/applications`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  const loadOptions3 = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/products`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }

  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      side_title_en: '',
      side_title_ar: '',
      description_en: '',
      description_ar: '',
      side_description_en: '',
      side_description_ar: '',
      overview_en: '',
      overview_ar: '',
      client_name_en: '',
      client_name_ar: '',
      industry_id: '',
      application_id: '',
      product_id: '',
      project_date: '',
      main_image: null,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editRequest(selectedRowId, endpoint, values)
        setModal(false)
        setChange(!change)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        const modal = document.getElementById('kt_modal_edit_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setModal(true)
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('main_image', selectedFile)
    }
  }

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Sub' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit {data.title_en}</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Title English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Title English'
                        {...formik.getFieldProps('title_en')}
                        className='form-control'
                        name='title_en'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Title Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Title Arabic'
                        {...formik.getFieldProps('title_ar')}
                        className='form-control'
                        name='title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description English</label>
                      <ReactQuill
                        value={formik.values.description_en}
                        onChange={(value) => formik.setFieldValue('description_en', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description English'
                      />
                      {formik.touched.description_en && formik.errors.description_en && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.description_en}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Description Arabic</label>
                      <ReactQuill
                        value={formik.values.description_ar}
                        onChange={(value) => formik.setFieldValue('description_ar', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Description English'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Side Title English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='side Title English'
                        {...formik.getFieldProps('side_title_en')}
                        className='form-control'
                        name='side_title_en'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Side Title Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Side Title Arabic'
                        {...formik.getFieldProps('side_title_ar')}
                        className='form-control'
                        name='side_title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Side Description English</label>
                      <ReactQuill
                        value={formik.values.side_description_en}
                        onChange={(value) => formik.setFieldValue('side_description_en', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Side Description English'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Side Description Arabic</label>
                      <ReactQuill
                        value={formik.values.side_description_ar}
                        onChange={(value) => formik.setFieldValue('side_description_ar', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                        placeholder='Side Description Arabic'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Overview English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Overview English'
                        {...formik.getFieldProps('overview_en')}
                        className='form-control'
                        name='overview_en'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Overview Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Overview Arabic'
                        {...formik.getFieldProps('overview_ar')}
                        className='form-control'
                        name='overview_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Client Name English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Client Name English'
                        {...formik.getFieldProps('client_name_en')}
                        className='form-control'
                        name='client_name_en'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Client Name Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Client Name Arabic'
                        {...formik.getFieldProps('client_name_ar')}
                        className='form-control'
                        name='client_name_ar'
                      />
                    </div>
                    {/* <div className='col-md-6  mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Industries</label>
                      <AsyncPaginate
                        loadOptions={loadOptions}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={selectedOption}
                        onChange={(options) => {
                          setSelectedOption(options)
                          const selectedValues = options.map((option) => option.value)
                          formik.setFieldValue('industry_id', selectedValues)
                        }}
                        inputValue={inputValue}
                        onInputChange={(value) => setInputValue(value)}
                        isClearable
                        isMulti
                      />
                    </div> */}
                    <div className='col-md-6  mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Applications</label>
                      <AsyncPaginate
                        loadOptions={loadOptions2}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={selectedOption2}
                        onChange={(options) => {
                          setSelectedOption2(options)
                          const selectedValues = options.map((option) => option.value)
                          formik.setFieldValue('application_id', selectedValues)
                        }}
                        inputValue={inputValue}
                        onInputChange={(value) => setInputValue(value)}
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className='col-md-6  mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Products</label>
                      <AsyncPaginate
                        loadOptions={loadOptions3}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={selectedOption3}
                        onChange={(options) => {
                          setSelectedOption3(options)
                          const selectedValues = options.map((option) => option.value)
                          formik.setFieldValue('product_id', selectedValues)
                        }}
                        inputValue={inputValue}
                        onInputChange={(value) => setInputValue(value)}
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className='col-md-6  mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>Project Date</label>
                      <input
                        type='date'
                        className='form-control'
                        autoComplete='off'
                        placeholder='Date'
                        {...formik.getFieldProps('project_date')}
                      />
                    </div>
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange}
                        className='form-control'
                        name='main_image'
                        ref={fileInputRef}
                      />
                    </div>
                    <div className='col-md-12 mt-5 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
