import React,{useState} from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { TableColumn } from 'react-data-table-component';
import {deleteAction,actionsDetails} from "../CrudActions/index"
import DataTableExample from '../apps/datatable/Datatable';


interface RowData {
  title_en: string;
  title_ar: string;
  id:number
  is_featured:string
  main_image:string
  catalog:string
  status:string
}

const ProductPage = () => {
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const rowID = selectedRowId
  const ModalEdit =`/edit`
  const endpoint='/v1/cables/admin/products'

  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )

  const CustomIsPopularCell = ({ value }) => {
    return <span>{value === true ? 'Yes' : 'No'}</span>;
  };
  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name English',
      selector: (row) => row.title_en,
      sortable: true,
    },
    {
      name: 'Name Arabic',
      selector: (row) => row.title_en,
      sortable: true,
    },
    {
      name: 'Image',
      selector: (row) => row.main_image,
      cell: (row) => 
                  <a href={`${API_URL}/${row.main_image}`} target='_blank'>
                    Preview Image
                  </a>,
      sortable: true,
    },
    {
      name: 'File',
      selector: (row) => row.catalog,
      cell: (row) => (
        <a href={`${API_URL}/${row.catalog}`} target='_blank'>
          Preivew File
        </a>
      ),
    },
    {
      name: 'Is Featured ?',
      cell: (row) => <CustomIsPopularCell value={row.is_featured} />,
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 'approved' ? 'badge-light-primary' : 'badge-light-warning'
          } fs-7 fw-semibold`}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    
  ]
  return (
    <>
       <DataTableExample
        endpoint={endpoint}
        columns={dynamicColumns}
        cardTitle='Products'
        addButtonLabel='Add New Product'
        ModalAdd='/add'
        actionsColumn={actionsColumn}
        change={change}
      />
    </>
  )
}

const ProductsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.Products'})}</PageTitle>
      <ProductPage />
    </>
  )
}

export {ProductsWrapper}
