import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import axios from 'axios'
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId, id}) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
      setData(response.data.data)
      formik.setValues({
        title_en: response.data.data.title_en || '',
        title_ar: response.data.data.title_ar || '',
        url: response.data.data.url || '',
        file: response.data.data.file || null,
        product_id: id,
      })
    })
  }, [API_URL, endpoint, selectedRowId])

  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      url: '',
      file: null,
      product_id: id,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, value]) => value !== '')
      ) 
      try {
        await editRequest(selectedRowId, endpoint, filteredValues)
        setChange(!change)
        setModal(false)
        setLoading(false)
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_edit_Doc')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setModal(true)
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Doc' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                {/* Form fields */}
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Title english</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='Configuration'
                        {...formik.getFieldProps('title_en')}
                        className='form-control'
                        name='title_en'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className=' fs-5 fw-semibold mb-2'>Title Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='title_ar'
                        {...formik.getFieldProps('title_ar')}
                        className='form-control'
                        name='title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className=' fs-5 fw-semibold mb-2'>url</label>
                      <input
                        type='text'
                        autoComplete='off'
                        placeholder='URL'
                        {...formik.getFieldProps('url')}
                        className='form-control'
                        name='url'
                      />
                    </div>
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>File</label>
                      <input
                        type='file'
                        onChange={handleImageChange} // Handle image change
                        className='form-control'
                        name='image'
                      />
                      {/* <a
                        href={`${API_URL}/${selectedImage ? selectedImage.name : data.image}`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : `${API_URL}/${data.image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a> */}
                    </div>

                    <div className='col-md-12 mt-5 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* End of form fields */}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
