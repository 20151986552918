/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const PartnersContentPage = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=our_partners&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=our_partners'
  const [selectedImage, setSelectedImage] = useState<File | null>(null)

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data)
      // Set initial values when data is available
      formik.setValues({
        title_en: response.data.data.our_partners.title_en || '',
        title_ar: response.data.data.our_partners.title_ar || '',
        description_en: response.data.data.our_partners.description_en || '',
        description_ar: response.data.data.our_partners.description_ar || '',
        image: response.data.data.our_partners.image || '',
        section_title_en: response.data.data.our_partners.section_title_en || '',
        section_title_ar: response.data.data.our_partners.section_title_ar || '',
        section_subtitle_en: response.data.data.our_partners.section_subtitle_en || '',
        section_subtitle_ar: response.data.data.our_partners.section_subtitle_ar || '',
        section_visibility_section1: response.data.data.our_partners.section_visibility_section1 || '',
        section_visibility_section2: response.data.data.our_partners.section_visibility_section2 || '',
        page_name: 'our_partners',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'our_partners',
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      image: '',
      section_title_en: '',
      section_title_ar: '',
      section_subtitle_en: '',
      section_subtitle_ar: '',
      section_visibility_section1: '',
      section_visibility_section2: '',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }

  const handleSectionVisibilityChange = (name: string, checked: boolean) => {
    const value = checked ? "1" : "0";
    formik.setFieldValue(name, value);
  };
  
  return (
    <>
      <div className='row mb-10'>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 mb-10'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>First section</span>
                </h3>
              </div>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('title_en')}
                  className='form-control'
                  name='title_en'
                />
              </div>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('title_ar')}
                  className='form-control'
                  name='title_ar'
                />
              </div>
              <div className='col-md-6 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Description English</label>
                <ReactQuill
                  value={formik.values.description_en}
                  onChange={(value) => formik.setFieldValue('description_en', value)}
                  modules={{toolbar: true}}
                  formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                />
              </div>
              <div className='col-md-6 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                <ReactQuill
                  value={formik.values.description_ar}
                  onChange={(value) => formik.setFieldValue('description_ar', value)}
                  modules={{toolbar: true}}
                  formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                />
              </div>
              {data && data.our_partners && (
                <div className='col-md-12 mt-5'>
                  <label className='fs-5 fw-semibold mb-2'>Image</label>
                  <input
                    type='file'
                    onChange={handleImageChange} // Handle image change
                    className='form-control'
                    name='image2' // Use a different name for this input field
                  />
                  <a
                    href={`${API_URL}/${
                      selectedImage ? selectedImage.name : data.our_partners.image
                    }`}
                    target='_blank'
                  >
                    <img
                      src={`${
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : `${API_URL}/${data.our_partners.image}`
                      }`}
                      alt='Image'
                      className='mt-5'
                      style={{height: '100px'}}
                    />
                  </a>
                </div>
              )}
              <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_section1'
                        {...formik.getFieldProps('section_visibility_section1')}
                        checked={formik.values.section_visibility_section1 === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_section1", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  <hr className='mt-10'/>
              <div className='col-md-12 mb-10 mt-10'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Logos section</span>
                </h3>
              </div>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_title_en')}
                  className='form-control'
                  name='section_title_en'
                />
              </div>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_title_ar')}
                  className='form-control'
                  name='section_title_ar'
                />
              </div>
              <div className='col-md-6 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Subtitle English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_subtitle_en')}
                  className='form-control'
                  name='section_subtitle_en'
                />
              </div>
              <div className='col-md-6 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Subtitle Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_subtitle_ar')}
                  className='form-control'
                  name='section_subtitle_ar'
                />
              </div>
              <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_section2'
                        {...formik.getFieldProps('section_visibility_section2')}
                        checked={formik.values.section_visibility_section2 === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_section2", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>

              <div className='col-md-12 mt-10 text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
