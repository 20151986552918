import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import DataTableExample from '../../apps/datatable/Datatable'
import { TableColumn } from 'react-data-table-component'
import { deleteAction, actionsDetails } from '../../CrudActions/index'
import { Add } from "./Add"
import{Edit} from"./Edit"

interface RowData {
  id: number
  name_en: string
  name_ar: string
  job_title_en: string
  job_title_ar: string
  sort: number
  status:string
}

const ManagementTeamsPage = () => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null) // State to hold selected row ID

  const [change, setChange] = useState(false)
  const endpoint = '/v1/cables/admin/management-team'
  const ModalEdit = '#kt_modal_edit_Sub'
  const rowID = selectedRowId
  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name English',
      selector: (row) => row.name_en,
      sortable: true,
    },
    {
      name: 'Name Arabic',
      selector: (row) => row.name_ar,
      sortable: true,
    },
    {
      name: 'Job Title English',
      selector: (row) => row.job_title_en,
      sortable: true,
    },
    {
      name: 'Job Title Arabic',
      selector: (row) => row.job_title_ar,
      sortable: true,
    },
    {
      name: 'Sorting',
      selector: (row) => row.sort,
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 'approved' ? 'badge-light-primary' : 'badge-light-warning'
          } fs-7 fw-semibold`}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
  ]
  
  return (
    <>
      <DataTableExample
        endpoint={endpoint}
        columns={dynamicColumns}
        cardTitle='Management Team'
        addButtonLabel='Add New Member'
        ModalAdd='#kt_modal_add_Sub'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
      />
    </>
  )
}

const ManagementTeamsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MANAGEMENTTEAMS'})}</PageTitle>
      <ManagementTeamsPage />
    </>
  )
}

export {ManagementTeamsWrapper}
