/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {MainData} from './MainData'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {DesignData} from './DesignData/DesignData'
import {MechanicalData} from './MechanicalData/MechincalData'
import {OpticalData} from './OpticalData/OpticalData'
import {Documents} from './Documents/Documents'
import {Specfications} from './Specfications/Specfications'
import {Classifications} from './Classifications/Classifications'
import {Images} from './Images/Images'

export const EditProduct = () => {
  const {id} = useParams()
  const [tab, setTab] = useState('MainData')
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.Products'})}</PageTitle>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'MainData',
                })}
                onClick={() => setTab('MainData')}
                role='tab'
              >
                Main Data
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'Images',
                })}
                onClick={() => setTab('Images')}
                role='tab'
              >
                 Images
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'Classification',
                })}
                onClick={() => setTab('Classification')}
                role='tab'
              >
                Product Classifications
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'Specfications',
                })}
                onClick={() => setTab('Specfications')}
                role='tab'
              >
                General Specfications
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'MechanicalData',
                })}
                onClick={() => setTab('MechanicalData')}
                role='tab'
              >
                Mechanical Data
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'DesignData',
                })}
                onClick={() => setTab('DesignData')}
                role='tab'
              >
                Deisgn Data
              </a>
            </li>
            
            <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'OpticalData',
                })}
                onClick={() => setTab('OpticalData')}
                role='tab'
              >
                Optical Data
              </a>
            </li>
            {/* <li className='nav-item'>
              <a
                className={clsx(`text-dark nav-link cursor-pointer`, {
                  active: tab === 'Documents',
                })}
                onClick={() => setTab('Documents')}
                role='tab'
              >
                Documents & Downloads
              </a>
            </li> */}
            
            
          </ul>
        </div>
        {/* end::Header */}

        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div className={clsx('tab-pane', {active: tab === 'MainData'})}>
              <MainData id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'Images'})}>
              <Images id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'DesignData'})}>
              <DesignData id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'MechanicalData'})}>
              <MechanicalData id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'OpticalData'})}>
              <OpticalData id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'Documents'})}>
              <Documents id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'Classification'})}>
              <Classifications id={id} />
            </div>
            <div className={clsx('tab-pane', {active: tab === 'Specfications'})}>
              <Specfications id={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
