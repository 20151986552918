import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {requestLogout} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth()
    }
  }
  

  const logout = async () => {
    const storedAuthString = localStorage.getItem('kt-auth-react-v');

  if (storedAuthString === null) {
    throw new Error("Authentication data not found in local storage");
  }

  const storedAuth = JSON.parse(storedAuthString);

  if (!storedAuth.tokens || !storedAuth.tokens.refresh || !storedAuth.tokens.refresh.token) {
    throw new Error("Refresh token is not available");
  }

  const refreshToken = storedAuth.tokens.refresh.token;
    if (refreshToken) {
      await requestLogout(refreshToken);
      saveAuth(undefined)
      setCurrentUser(undefined)
    }
  };
  

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string, refreshToken: string) => {
      try {
        if (!didRequest.current) {
          setCurrentUser(auth?.user);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
  
      return () => (didRequest.current = true);
    };
  
    if (auth && auth.tokens.refresh.token && auth.tokens.access.token) {
      requestUser(auth.tokens.access.token, auth.tokens.refresh.token);
    } else {
      console.log("No refresh token available, logging out.");
      logout();
      setShowSplashScreen(false);
    }
    
    // eslint-disable-next-line
  }, []);
  

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
