/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'


interface Product {
  title: string
}

interface RowData {
  id: number
  name: string
  email: string
  company: string
  country: string
  product_id: string
  product: Product
}

type Props = {
  className: string
  data: RowData[]
}

const TablesWidget11: React.FC<Props> = ({className, data}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted '>
                <th style={{width: '2%'}}>ID</th>
                <th style={{width: '20%'}}>Name</th>
                <th style={{width: '20%'}}>Email</th>
                <th style={{width: '20%'}}>Company</th>
                <th style={{width: '30%'}}>Country</th>
                <th style={{width: '10%'}}>Product</th>
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>

            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td style={{width: '2%'}}>{row.id}</td>
                  <td style={{width: '20%'}}>{row.name}</td>
                  <td style={{width: '20%'}}>{row.email}</td>
                  <td style={{width: '15%'}}>{row.company}</td>
                  <td style={{width: '30%'}}>{row.country}</td>
                  <td style={{width: '10%'}}>{row.product && row.product.title}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export type {RowData} // Use 'export type' to re-export the type

export {TablesWidget11}
