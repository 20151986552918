/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../Components/Requests'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

export const MainData = () => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const endpoint = '/v1/cables/admin/products'
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [selectedOption, setSelectedOption] = useState<any | null>(null)
  const [selectedOption2, setSelectedOption2] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const navigate = useNavigate()

  const addSchema = Yup.object().shape({
    title_en: Yup.string().required('Title English is required'),
    title_ar: Yup.string().required('Title Arabic is required'),
    description_en: Yup.string().required('Description English is required'),
    description_ar: Yup.string().required('DescriptionArabic is required'),
    features_en: Yup.string().required('Features English required'),
    features_ar: Yup.string().required('features Arabic is required'),
    // industry_id: Yup.array().required('Industry is required'),
    application_id: Yup.array().required('Application Arabic is required'),
    main_image: Yup.string().required('Image is required'),
    catalog: Yup.string().required('Image is required'),
  })
  const initialValues = {
    title_en: '',
    title_ar: '',
    description_en: '',
    description_ar: '',
    features_en: '',
    features_ar: '',
    is_featured: false,
    //industry_id: ['21'],
    application_id: '',
    main_image: '',
    catalog: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: addSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        // Set industry_id to an array containing '21'
       // values.industry_id = ['21']


        const response = await postRequest(endpoint, values)

        // resetForm();
        setSelectedOption(null)
        setSelectedOption2(null)

        if (response) {
          navigate(`/edit/${response.id}`)
        }

        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const loadOptions = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/industries`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []} // Return an empty options array on error
    }
  }
  const loadOptions2 = async (searchQuery: string, apiEndpoint: any) => {
    try {
      const response = await axios.get(`${API_URL}/v1/cables/admin/applications`, {
        params: {query: searchQuery},
      })
      const options = response.data.data.data.map((item: any) => ({
        value: item.id,
        label: item.title,
      }))

      return {
        options,
        hasMore: false,
        additional: {
          page: 1,
        },
      }
    } catch (error) {
      console.error('Error loading options:', error)
      return {options: []}
    }
  }
  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <label className='required fs-5 fw-semibold mb-2'>Title English</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Enter Title English'
                {...formik.getFieldProps('title_en')}
                className='form-control'
                name='title_en'
              />
              {formik.touched.title_en && formik.errors.title_en && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title_en}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <label className='required fs-5 fw-semibold mb-2'>Title Arabic</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Enter Title Arabic'
                {...formik.getFieldProps('title_ar')}
                className='form-control'
                name='title_ar'
              />
              {formik.touched.title_ar && formik.errors.title_ar && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title_ar}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Description English</label>
              <ReactQuill
                value={formik.values.description_en}
                onChange={(value) => formik.setFieldValue('description_en', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Description English'
              />
              {formik.touched.description_en && formik.errors.description_en && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description_en}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Description Arabic</label>
              <ReactQuill
                value={formik.values.description_ar}
                onChange={(value) => formik.setFieldValue('description_ar', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Description Arabic'
              />
              {formik.touched.description_ar && formik.errors.description_ar && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description_ar}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Features English</label>
              <ReactQuill
                value={formik.values.features_en}
                onChange={(value) => formik.setFieldValue('features_en', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Features English'
              />
              {formik.touched.features_en && formik.errors.features_en && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.features_en}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Features Arabic</label>
              <ReactQuill
                value={formik.values.features_ar}
                onChange={(value) => formik.setFieldValue('features_ar', value)}
                modules={{toolbar: true}}
                formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                placeholder='Features Arabic'
              />
              {formik.touched.features_ar && formik.errors.features_ar && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.features_ar}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='col-md-6  mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Industries</label>
              <AsyncPaginate
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={selectedOption}
                onChange={(options) => {
                  setSelectedOption(options) // options is an array of selected values
                  const selectedValues = options.map((option) => option.value)
                  formik.setFieldValue('industry_id', selectedValues)
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
                isMulti
              />

              {formik.touched.industry_id && formik.errors.industry_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.industry_id}</span>
                  </div>
                </div>
              )}
            </div> */}
            <div className='col-md-6  mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Applications</label>
              <AsyncPaginate
                loadOptions={loadOptions2}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={selectedOption2}
                onChange={(options) => {
                  setSelectedOption2(options)
                  const selectedValues = options.map((option) => option.value)
                  formik.setFieldValue('application_id', selectedValues)
                }}
                inputValue={inputValue}
                onInputChange={(value) => setInputValue(value)}
                isClearable
                isMulti
              />
              {formik.touched.application_id && formik.errors.application_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.application_id}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6  mt-5'>
              <label className='fs-5 fw-semibold mb-2'>Is Featured ? </label>
              <br />
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  type='checkbox'
                  checked={formik.values.is_featured}
                  onChange={formik.handleChange}
                  className='form-check-input w-45px h-30px'
                  name='is_featured'
                />
                <label className='form-check-label'></label>
              </div>
            </div>
            <div className='col-md-12  mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Main Image</label>
              <input
                type='file'
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
                  if (selectedFile) {
                    formik.setFieldValue('main_image', selectedFile)
                  }
                }}
                className='form-control'
                name='main_image'
              />
              {formik.touched.main_image && formik.errors.main_image && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.main_image}</span>
                  </div>
                </div>
              )}
              <p>
                Recommended size: 275x185
                <br />
                Aspect ratio 16:9
              </p>
            </div>
            <div className='col-md-12  mt-5'>
              <label className='required fs-5 fw-semibold mb-2'>Catalog</label>
              <input
                type='file'
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
                  if (selectedFile) {
                    formik.setFieldValue('catalog', selectedFile)
                  }
                }}
                className='form-control'
                name='catalog'
              />
              {formik.touched.catalog && formik.errors.catalog && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.catalog}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-12 mt-5 text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
