import { TableColumn } from "react-data-table-component"
import DataTableExample from "../../../apps/datatable/Datatable"
import { useState } from "react"
import { actionsDetails, deleteAction } from "../../../CrudActions"
import { Add } from "./Add"
import { Edit } from "./Edit"


interface RowData {
  id: number
  title_en: string
  title_ar: string
  file:string
  status:string
}



export const Slider = () => {
     const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
     const API_URL = process.env.REACT_APP_API_URL || ''
     const [change, setChange] = useState(false)
     const endpoint = '/v1/cables/admin/slider'
     const ModalEdit = '#kt_modal_edit_Sub'
     const rowID = selectedRowId
     const actionsColumn: TableColumn<RowData> = actionsDetails(
       deleteAction,
       endpoint,
       setChange,
       change,
       ModalEdit,
       rowID,
       setSelectedRowId
     )

    const dynamicColumns: TableColumn<RowData>[] = [
      {
        name: 'ID',
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: 'Title English',
        selector: (row) => row.title_en,
        sortable: true,
      },
      {
        name: 'Title Arabic',
        selector: (row) => row.title_ar,
        sortable: true,
      },
      {
        name: 'Image',
        selector: (row) => row.file,
        cell: (row) => 
                    <a href={`${API_URL}/${row.file}`} target='_blank'>
                      Preview Image
                    </a>,
        sortable: true,
      },
      {
        name: 'Status',
        cell: (row) => (
          <span
            className={`badge ${
              row.status === 'approved' ? 'badge-light-primary' : 'badge-light-warning'
            } fs-7 fw-semibold`}
          >
            {row.status}
          </span>
        ),
        sortable: true,
      },
    ]
  return (
    <>
      <div className='row mb-10'>
        <DataTableExample
          endpoint={endpoint}
          columns={dynamicColumns}
          cardTitle='Slider'
          addButtonLabel='Add Slider'
          ModalAdd='#kt_modal_add_Sub'
          actionsColumn={actionsColumn}
          change={change}
        />
        <Add change={change} setChange={setChange} endpoint={endpoint} />
        <Edit
          change={change}
          setChange={setChange}
          endpoint={endpoint}
          selectedRowId={selectedRowId}
        />
      </div>
    </>
  )
}
