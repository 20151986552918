/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Home'
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />
      <AsideMenuItemWithSubMain
        to=''
        title='Cms'
        fontIcon='bi-archive fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.CMS'})}
      >
        <AsideMenuItem
          to='/homepage'
          title='Home Page'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.HOMEPAGE'})}
        />
        <AsideMenuItem
          to='/about_benya'
          title='About Benya'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.ABOUTBENYA'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/our_partners'
          title='Partners'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.OURPARTNERS'})}
          className='py-2'
        />
        {/* <AsideMenuItem
          to='/chairman_message'
          title='Chairman Message'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.CHAIRMANMESSAGE'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/management_teams'
          title='Management Teams'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.MANAGEMENTTEAMS'})}
          className='py-2'
        /> */}
        <AsideMenuItem
          to='/our_factory'
          title='Factory'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.OURFACTORY'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/contact_us'
          title='Contact Us'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.CONTACTUS'})}
          className='py-2'
        />

        <AsideMenuItem
          to='/careers_page'
          title='Careers'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.CAREERS'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/contact_form'
          title='Contact Form'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Contact Form'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/get_quotation'
          title='Get Quotation'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Get Quotation Form'})}
          className='py-2'
        />
        <AsideMenuItem
          to='/career_form'
          title='Career Form'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Career Form'})}
          className='py-2'
        />
        {/* <AsideMenuItem
          to='/get_a_quote'
          title='Get a Quote'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.GETAQUOTE'})}
          className='py-2'
        /> */}
      </AsideMenuItemWithSubMain>
      <AsideMenuItemWithSubMain
        to=''
        title='Products'
        fontIcon='bi-list-nested fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.Products'})}
      >
        <AsideMenuItem
          to='/products'
          title='Products'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Products'})}
        />
        <AsideMenuItem
          to='/product-specfications'
          title='Product Specfications'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Specfications'})}
        />
        {/* <AsideMenuItem
          to='/industries'
          title='Industries'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Industries'})}
          className='py-2'
        /> */}
        <AsideMenuItem
          to='/applications'
          title='Applications'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Applications'})}
          className='py-2'
        />
      </AsideMenuItemWithSubMain>
      <AsideMenuItemWithSubMain
        to=''
        title='Projects'
        fontIcon='bi-kanban-fill fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.Projects'})}
      >
        <AsideMenuItem
          to='/projects'
          title='Projects'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Projects'})}
        />
        {/* <AsideMenuItem
          to='/industries'
          title='Industries'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Industries'})}
          className='py-2'
        /> */}
        <AsideMenuItem
          to='/applications'
          title='Applications'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Applications'})}
          className='py-2'
        />
      </AsideMenuItemWithSubMain>
      <AsideMenuItemWithSubMain
        to=''
        title='Newsroom'
        fontIcon='bi-newspaper fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.Newsroom'})}
      >
        <AsideMenuItem
          to='/newsroom'
          title='Newsroom'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Newsroom'})}
        />
        <AsideMenuItem
          to='/subsidiaries'
          title='Subsidiaries'
          hasBullet={true}
          bsTitle={intl.formatMessage({id: 'MENU.Subsidiaries'})}
          className='py-2'
        />
      </AsideMenuItemWithSubMain>
      <AsideMenuItem
        to='/seo'
        title='SEO'
        fontIcon='bi-search fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.SEO'})}
        className='py-2'
      />
    </>
  )
}
