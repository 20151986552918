import {useState, useRef} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint, id}) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  const supportedExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.xlsx']
  // const fileValidation = Yup.string()
  //       .test('fileType', 'Invalid file format must be (.pdf, .doc, .docx, .ppt, .xlsx)', (value) => {
  //         if (!value) return true;

  //         const extension = value.split('.').pop();
  //         return supportedExtensions.includes(`.${extension}`);
  //       })
  //       .required('Image is required');
  const addSchema = Yup.object().shape({
    title_en: Yup.string().required('Title English is required'),
    title_ar: Yup.string().required('Title Arabic is required'),
    url: Yup.string().url('URL must be a valid URI with http, https, or ftp scheme'),
    file: Yup.string().required('File is required'),
  })

  const formik = useFormik({
    validationSchema: addSchema,
    initialValues: {
      title_en: '',
      title_ar: '',
      url: '',
      file: '',
      product_id: id,
    },

    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, value]) => value !== '')
      ) 
      try {
        await postRequest(endpoint, filteredValues) // Use filteredValues instead of values
        setChange(!change)
        setModal(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        resetForm()
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
        const modal = document.getElementById('kt_modal_add_Doc')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setModal(true)
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Doc' aria-hidden='true' role='dialog'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Document</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              {/* Form fields */}
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title English'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                    {formik.touched.title_en && formik.errors.title_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Max Allowed</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Max Allowed'
                      {...formik.getFieldProps('max_allowed')}
                      className='form-control'
                      name='max_allowed'
                    />
                    {formik.touched.max_allowed && formik.errors.max_allowed && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.max_allowed}</span>
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title Arabic'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                    {formik.touched.title_ar && formik.errors.title_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className=' fs-5 fw-semibold mb-2'>URL</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='URL'
                      {...formik.getFieldProps('url')} // Bind to the "url" field
                      className='form-control'
                      name='url'
                    />

                    {formik.touched.url && formik.errors.url && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.url}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>File</label>
                    <input
                      type='file'
                      ref={fileInputRef}
                      onChange={(event) => {
                        const selectedFile =
                          event.currentTarget.files && event.currentTarget.files[0]
                        if (selectedFile) {
                          formik.setFieldValue('file', selectedFile)
                        }
                      }}
                      className='form-control'
                      name='file'
                    />
                    {formik.touched.file && formik.errors.file && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.file}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* End of form fields */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
