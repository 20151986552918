/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuthenticatedRequest} from '../Components/Requests'
import {AsyncPaginate} from 'react-select-async-paginate'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint}: any) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [selectedOption, setSelectedOption] = useState<any>()
  const [inputValue, setInputValue] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  const addSchema = Yup.object().shape({
    title_en: Yup.string().required('Title English is required'),
    title_ar: Yup.string().required('Title Arabic is required'),
    description_en: Yup.string().required('Description English is required'),
    description_ar: Yup.string().required('Description Arabic is required'),
    subsidiary_id: Yup.string().required('Subsidiary is required'),
    image: Yup.string().required('Image is required'),
  })
  const initialValues = {
    title_en: '',
    title_ar: '',
    description_en: '',
    description_ar: '',
    subsidiary_id: '',
    image: '',
    is_popular: false,
  }
  const loadOptions = async (searchQuery: string, loadedOptions: any) => {
    const response = await axios.get(`${API_URL}/v1/cables/admin/subsidiaries`, {
      params: {query: searchQuery},
    })

    const options = response.data.data.data.map((item: any) => ({
      value: item.id,
      label: item.title,
    }))

    return {
      options,
      hasMore: false,
      additional: {
        page: 1,
      },
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await postRequest(endpoint, values)
        setChange(!change)
        setModal(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        setSelectedOption(null)
        resetForm()
        values.is_popular = false
        toast.success('Form submitted successfully')
        const modal = document.getElementById('kt_modal_add_Sub')
        if (modal) {
          modal.classList.remove('show')
          modal.style.display = 'none'
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
          if (modalBackdrop) {
            modalBackdrop.parentNode?.removeChild(modalBackdrop)
          }
        }
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
        setModal(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Sub' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Article</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Name English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title English'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                    {formik.touched.title_en && formik.errors.title_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label className='required fs-5 fw-semibold mb-2'>Name Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      placeholder='Title Arabic'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                    {formik.touched.title_ar && formik.errors.title_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Description English'
                    />
                    {formik.touched.description_en && formik.errors.description_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description_en}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder='Description Arabic'
                    />
                    {formik.touched.description_ar && formik.errors.description_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Subsidiary</label>
                    <AsyncPaginate
                      loadOptions={loadOptions}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      value={selectedOption}
                      onChange={(option) => {
                        setSelectedOption(option)
                        formik.setFieldValue('subsidiary_id', option?.value)
                      }}
                      inputValue={inputValue}
                      onInputChange={(value) => setInputValue(value)}
                      isClearable
                    />
                    {formik.touched.subsidiary_id && formik.errors.subsidiary_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.subsidiary_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6  mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Is Popular ? </label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        type='checkbox'
                        checked={formik.values.is_popular}
                        onChange={formik.handleChange}
                        className='form-check-input w-45px h-30px'
                        name='is_popular'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  <div className='col-md-12  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Image</label>
                    <input
                      type='file'
                      ref={fileInputRef}
                      onChange={(event) => {
                        const selectedFile =
                          event.currentTarget.files && event.currentTarget.files[0]
                        console.log(selectedFile)
                        if (selectedFile) {
                          formik.setFieldValue('image', selectedFile)
                        }
                      }}
                      className='form-control'
                      name='image'
                    />
                    {formik.touched.image && formik.errors.image && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.image}</span>
                        </div>
                      </div>
                    )}
                    <p>
                      Recommended size: 470x265
                      <br />
                      Aspect ratio 16:9
                    </p>
                  </div>
                  <div className='col-md-12 mt-5 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={formik.isValid ? (modal ? 'modal' : '') : ''}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                      <span className='indicator-label'>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
