import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const ContactPage = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=contact_us&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=contact_us'

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data.contact_us)
      // Set initial values when data is available
      formik.setValues({
        email: response.data.data.contact_us.email || '',
        address_en: response.data.data.contact_us.address_en || '',
        address_ar: response.data.data.contact_us.address_ar || '',
        title_en: response.data.data.contact_us.title_en || '',
        title_ar: response.data.data.contact_us.title_ar || '',
        subtitle_en: response.data.data.contact_us.subtitle_en || '',
        subtitle_ar: response.data.data.contact_us.subtitle_ar || '',
        description_en: response.data.data.contact_us.description_en || '',
        description_ar: response.data.data.contact_us.description_ar || '',
        google_url: response.data.data.contact_us.google_url || '',
        facebook_link: response.data.data.contact_us.facebook_link || '',
        linkedin_link: response.data.data.contact_us.linkedin_link || '',
        page_name: 'contact_us',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'contact_us',
      email: '',
      address_en: '',
      address_ar: '',
      title_en: '',
      title_ar: '',
      subtitle_en: '',
      subtitle_ar: '',
      description_en: '',
      description_ar: '',
      google_url: '',
      facebook_link: '',
      linkedin_link: '',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle_en')}
                      className='form-control'
                      name='subtitle_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Subtitle Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('subtitle_ar')}
                      className='form-control'
                      name='subtitle_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>E-Mail</label>
                    <input
                      type='email'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className='form-control'
                      name='email'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Google Maps URL</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('google_url')}
                      className='form-control'
                      name='google_url'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Address English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('address_en')}
                      className='form-control'
                      name='address_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Address Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('address_ar')}
                      className='form-control'
                      name='address_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Facbook Link</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('facebook_link')}
                      className='form-control'
                      name='facebook_link'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Linkedin Link</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('linkedin_link')}
                      className='form-control'
                      name='linkedin_link'
                    />
                  </div>
                  <div className='col-md-12 mt-10 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

const ContactWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CONTACTUS'})}</PageTitle>
      <ContactPage />
    </>
  )
}

export {ContactWrapper}
