import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const FactoryPage = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=our_factory&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=our_factory'
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [selectedImage2, setSelectedImage2] = useState<File | null>(null)
  const [selectedImage3, setSelectedImage3] = useState<File | null>(null)

  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data)
      // Set initial values when data is available
      formik.setValues({
        title_en: response.data.data.our_factory.title_en || '',
        title_ar: response.data.data.our_factory.title_ar || '',
        description_en: response.data.data.our_factory.description_en || '',
        description_ar: response.data.data.our_factory.description_ar || '',
        image: response.data.data.our_factory.image || null,
        section_visibility_banner: response.data.data.our_factory.section_visibility_banner || '',

        counter_number1: response.data.data.our_factory.counter_number1 || '',
        counter_title1_en: response.data.data.our_factory.counter_title1_en || '',
        counter_title1_ar: response.data.data.our_factory.counter_title1_ar || '',

        counter_number2: response.data.data.our_factory.counter_number2 || '',
        counter_title2_en: response.data.data.our_factory.counter_title2_en || '',
        counter_title2_ar: response.data.data.our_factory.counter_title2_ar || '',

        counter_number3: response.data.data.our_factory.counter_number3 || '',
        counter_title3_en: response.data.data.our_factory.counter_title3_en || '',
        counter_title3_ar: response.data.data.our_factory.counter_title3_ar || '',

        counter_number4: response.data.data.our_factory.counter_number4 || '',
        counter_title4_en: response.data.data.our_factory.counter_title4_en || '',
        counter_title4_ar: response.data.data.our_factory.counter_title4_ar || '',

        section_visibility_counter: response.data.data.our_factory.section_visibility_counter || '',

        section1_title_en: response.data.data.our_factory.section1_title_en || '',
        section1_title_ar: response.data.data.our_factory.section1_title_ar || '',
        section1_description_en: response.data.data.our_factory.section1_description_en || '',
        section1_description_ar: response.data.data.our_factory.section1_description_ar || '',
        section1_image: response.data.data.our_factory.section1_image || null,
        section_visibility_section1: response.data.data.our_factory.section_visibility_section1 || '',

        section2_title_en: response.data.data.our_factory.section2_title_en || '',
        section2_title_ar: response.data.data.our_factory.section2_title_ar || '',
        section2_description_en: response.data.data.our_factory.section2_description_en || '',
        section2_description_ar: response.data.data.our_factory.section2_description_ar || '',
        section2_image: response.data.data.our_factory.section2_image || null,
        section_visibility_section2: response.data.data.our_factory.section_visibility_section2 || '',

        page_name: 'our_factory',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'our_factory',
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      image: null,

      counter_number1: '',
      counter_title1_en: '',
      counter_title1_ar: '',
      counter_number2: '',
      counter_title2_en: '',
      counter_title2_ar: '',
      counter_number3: '',
      counter_title3_en: '',
      counter_title3_ar: '',
      counter_number4: '',
      counter_title4_en: '',
      counter_title4_ar: '',

      section1_title_en: '',
      section1_title_ar: '',
      section1_description_en: '',
      section1_description_ar: '',
      section1_image: null,

      section2_title_en: '',
      section2_title_ar: '',
      section2_description_en: '',
      section2_description_ar: '',
      section2_image: null,

      section_visibility_banner:'',
      section_visibility_counter:'',
      section_visibility_section1:'',
      section_visibility_section2:'',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }
  const handleImageChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage2(selectedFile)
      formik.setFieldValue('section1_image', selectedFile)
    }
  }
  const handleImageChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage3(selectedFile)
      formik.setFieldValue('section2_image', selectedFile)
    }
  }

  const handleSectionVisibilityChange = (name: string, checked: boolean) => {
    const value = checked ? "1" : "0";
    formik.setFieldValue(name, value);
  };
  

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>

                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  {data && data.our_factory && (
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange} // Handle image change
                        className='form-control'
                        name='image' // Use a different name for this input field
                      />
                      <a
                        href={`${API_URL}/${
                          selectedImage ? selectedImage.name : data.our_factory.image
                        }`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : `${API_URL}/${data.our_factory.image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                  )}

                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_banner'
                        {...formik.getFieldProps('section_visibility_banner')}
                        checked={formik.values.section_visibility_banner === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_banner", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  <hr className='mt-10'/>

                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Counter 1</span>
                    </h3>
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Number</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_number1')}
                      className='form-control'
                      name='counter_number1'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title1_en')}
                      className='form-control'
                      name='counter_title1_en'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title1_ar')}
                      className='form-control'
                      name='counter_title1_ar'
                    />
                  </div>

                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Counter 2</span>
                    </h3>
                  </div>

                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Number</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_number2')}
                      className='form-control'
                      name='counter_number2'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title2_en')}
                      className='form-control'
                      name='counter_title2_en'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title2_ar')}
                      className='form-control'
                      name='counter_title2_ar'
                    />
                  </div>

                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Counter 3</span>
                    </h3>
                  </div>

                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Number</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_number3')}
                      className='form-control'
                      name='counter_number3'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title3_en')}
                      className='form-control'
                      name='counter_title3_en'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title3_ar')}
                      className='form-control'
                      name='counter_title3_ar'
                    />
                  </div>

                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Counter 4</span>
                    </h3>
                  </div>

                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Number</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_number4')}
                      className='form-control'
                      name='counter_number4'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title4_en')}
                      className='form-control'
                      name='counter_title4_en'
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('counter_title4_ar')}
                      className='form-control'
                      name='counter_title4_ar'
                    />
                  </div>

                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_counter'
                        {...formik.getFieldProps('section_visibility_counter')}
                        checked={formik.values.section_visibility_counter === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_counter", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  <hr className='mt-10'/>

                  <div className='col-md-12 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>First Section</span>
                    </h3>
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('section1_title_en')}
                      className='form-control'
                      name='section1_title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('section1_title_ar')}
                      className='form-control'
                      name='section1_title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.section1_description_en}
                      onChange={(value) => formik.setFieldValue('section1_description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.section1_description_ar}
                      onChange={(value) => formik.setFieldValue('section1_description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  {data && data.our_factory && (
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange2} // Handle image change
                        className='form-control'
                        name='section1_image'
                      />
                      <a
                        href={`${API_URL}/${
                          selectedImage2 ? selectedImage2.name : data.our_factory.section1_image
                        }`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage2
                              ? URL.createObjectURL(selectedImage2)
                              : `${API_URL}/${data.our_factory.section1_image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                  )}

                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_section1'
                        {...formik.getFieldProps('section_visibility_section1')}
                        checked={formik.values.section_visibility_section1 === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_section1", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  <hr className='mt-10'/>


                  <div className='col-md-12 mb-5 mt-10'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Second Section</span>
                    </h3>
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('section2_title_en')}
                      className='form-control'
                      name='section2_title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('section2_title_ar')}
                      className='form-control'
                      name='section2_title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.section2_description_en}
                      onChange={(value) => formik.setFieldValue('section2_description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.section2_description_ar}
                      onChange={(value) => formik.setFieldValue('section2_description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  {data && data.our_factory && (
                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange3} // Handle image change
                        className='form-control'
                        name='section2_image' // Use a different name for this input field
                      />
                      <a
                        href={`${API_URL}/${
                          selectedImage3 ? selectedImage3.name : data.our_factory.section2_image
                        }`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage3
                              ? URL.createObjectURL(selectedImage3)
                              : `${API_URL}/${data.our_factory.section2_image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                  )}
                  <div className='col-md-6 mt-5'>
                    <label className='fs-5 fw-semibold mb-2'>Section visibility</label>
                    <br />
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='section_visibility_section2'
                        {...formik.getFieldProps('section_visibility_section2')}
                        checked={formik.values.section_visibility_section2 === "1"}
                        onChange={(e) =>
                          handleSectionVisibilityChange("section_visibility_section2", e.target.checked)
                        }
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                  
                  <div className='col-md-12 mt-10 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

const FactoryWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OURFACTORY'})}</PageTitle>
      <FactoryPage />
    </>
  )
}

export {FactoryWrapper}
