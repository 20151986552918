import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import clsx from 'clsx'
import { WhyBenyaCables } from './WhyBenyaCables'
import { Slider } from './Slider/Slider'
import { VisibilitySections } from './VisibilitySections'

const HomePage = () => {
  const [tab, setTab] = useState('WhyBenyaCables')

  return (
    <>
        <div className='card card-custom'>
          <div className='card-header card-header-stretch overflow-auto'>
            <ul
              className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
              role='tablist'
            >
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'WhyBenyaCables',
                  })}
                  onClick={() => setTab('WhyBenyaCables')}
                  role='tab'
                >
                  Why Benya Cables
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'Slider',
                  })}
                  onClick={() => setTab('Slider')}
                  role='tab'
                >
                  Slider
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'VisibilitySections',
                  })}
                  onClick={() => setTab('VisibilitySections')}
                  role='tab'
                >
                  Visibility for sections
                </a>
              </li>
            </ul>
          </div>
        {/* end::Header */}

          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', {active: tab === 'WhyBenyaCables'})}>
                <WhyBenyaCables />
              </div>
              <div className={clsx('tab-pane', {active: tab === 'Slider'})}>
                <Slider />
              </div>
              <div className={clsx('tab-pane', {active: tab === 'VisibilitySections'})}>
                <VisibilitySections />
              </div>
            </div>
          </div>
        
      </div>
    </>
  )
}

const HomeWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOMEPAGE'})}</PageTitle>
      <HomePage />
    </>
  )
}

export {HomeWrapper}
