import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {TablesWidgetCareer} from '../../../_metronic/partials/widgets/tables/TablesWidgetCareer'
import {useAuthenticatedRequest} from '../Components/Requests'

const CareerFormPage = () => {
  const [data, setData] = useState<any[]>([])

  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalResults: 0,
    currentPage: 1,
  })

  const endpoint = '/v1/cables/admin/career'

  useEffect(() => {
    fetchData(endpoint, pagination.currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, pagination.currentPage])
  const {getRequest} = useAuthenticatedRequest()

  const fetchData = async (url: string, page: number) => {
    try {
      const response = await getRequest(url, {
        page: page.toString(),
      })
      const result = response.data
      console.log(result)
      setData(result)
      setPagination({
        totalPages: result.totalPages,
        totalResults: result.totalResults,
        currentPage: page,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <TablesWidgetCareer className={''} data={data} />
    </>
  )
}

const CareerFormWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.Career Form'})}</PageTitle>
      <CareerFormPage />
    </>
  )
}

export {CareerFormWrapper}
