/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import {useAuthenticatedRequest} from '../../../Components/Requests'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {toast} from 'react-toastify'

export const Add = ({setChange, change, endpoint}: any) => {
  const [loading, setLoading] = useState(false)
  const {postRequest} = useAuthenticatedRequest()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  const addSchema = Yup.object().shape({
    title_en: Yup.string().required('Title English is required'),
    title_ar: Yup.string().required('Title Arabic is required'),
    description_en: Yup.string().required('Description English is required'),
    description_ar: Yup.string().required('Description Arabic is required'),
    file: Yup.string().required('Image is required'),
  })
  const initialValues = {
    title_en: '',
    title_ar: '',
    description_en: '',
    description_ar: '',
    file: '',
    color:'',
    opacity:'',
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema: addSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await postRequest(endpoint, values)
        setModal(false)
        setChange(!change)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        resetForm()
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
      } catch (error: any) {
        setLoading(false)
        setModal(true)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_Sub' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Slider</h1>
            </div>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Title English</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_en')}
                      className='form-control'
                      name='title_en'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Title Arabic</label>
                    <input
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('title_ar')}
                      className='form-control'
                      name='title_ar'
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description English</label>
                    <ReactQuill
                      value={formik.values.description_en}
                      onChange={(value) => formik.setFieldValue('description_en', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Description Arabic</label>
                    <ReactQuill
                      value={formik.values.description_ar}
                      onChange={(value) => formik.setFieldValue('description_ar', value)}
                      modules={{toolbar: true}}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    />
                  </div>
                  <div className='col-md-12  mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Image</label>
                    <input
                      type='file'
                      ref={fileInputRef}
                      onChange={(event) => {
                        const selectedFile =
                          event.currentTarget.files && event.currentTarget.files[0]
                        if (selectedFile) {
                          formik.setFieldValue('file', selectedFile)
                        }
                      }}
                      className='form-control'
                      name='file'
                    />
                    {formik.touched.file && formik.errors.file && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.file}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6 mt-5'>
                    <label className='required fs-5 fw-semibold mb-2'>Color:</label>
                      <input
                        type='color'
                        autoComplete='off'
                        {...formik.getFieldProps('color')}
                        className='form-control'
                        name='color'
                       id='colorInput'
                      />
                  </div>
                    <div className='col-md-6 mt-5'>
                      <label className='required fs-5 fw-semibold mb-2'>Opacity:</label>
                        <input
                          type='range'
                          min='0'
                          max='1'
                          step='0.01'
                          {...formik.getFieldProps('opacity')}
                          className='form-control'
                          name='opacity'
                        />
                        <span id='opacityValue'>Opacity Value: {formik.values.opacity}</span>
                    </div>
                    <div>
                  </div>

                  <div className='col-md-12 mt-10 text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                      data-bs-dismiss={modal ? '' : 'modal'}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
