/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../Components/Requests'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {toast} from 'react-toastify'

export const Edit = ({setChange, change, endpoint, selectedRowId}: any) => {
  const [loading, setLoading] = useState(false)
  const {editRequest} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    setSelectedImage(null)
    if (selectedRowId !== null) {
      axios.get(`${API_URL}${endpoint}/${selectedRowId}`).then((response) => {
        setData(response.data.data)
        formik.setValues({
          name_en: response.data.data.name_en || '',
          name_ar: response.data.data.name_ar || '',
          description_en: response.data.data.description_en || '',
          description_ar: response.data.data.description_ar || '',
          job_title_en: response.data.data.job_title_en || '',
          job_title_ar: response.data.data.job_title_ar || '',
          sort: response.data.data.sort || '',
          image: response.data.data.image || null,
        })
      })
    }
  }, [API_URL, endpoint, selectedRowId])

  const formik = useFormik({
    initialValues: {
      name_en: '',
      name_ar: '',
      description_en: '',
      description_ar: '',
      job_title_en: '',
      job_title_ar: '',
      sort: '',
      image: null,
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editRequest(selectedRowId, endpoint, values)
        setModal(false)
        setChange(!change)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: false,
          theme: 'colored',
        })
      } catch (error: any) {
        setLoading(false)
        setModal(true)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0]
    if (selectedFile) {
      setSelectedImage(selectedFile)
      formik.setFieldValue('image', selectedFile)
    }
  }

  return (
    data && (
      <div className='modal fade' id='kt_modal_edit_Sub' aria-hidden='true' role='dialog'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit {data.name}</h1>
              </div>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_add_form'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Name in English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('name_en')}
                        className='form-control'
                        name='name_en'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Name in Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('name_ar')}
                        className='form-control'
                        name='name_ar'
                      />
                    </div>

                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Job Title English</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('job_title_en')}
                        className='form-control'
                        name='job_title_en'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Job Title Arabic</label>
                      <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('job_title_ar')}
                        className='form-control'
                        name='job_title_ar'
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Description English</label>
                      <ReactQuill
                        value={formik.values.description_en}
                        onChange={(value) => formik.setFieldValue('description_en', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Description Arabic</label>
                      <ReactQuill
                        value={formik.values.description_ar}
                        onChange={(value) => formik.setFieldValue('description_ar', value)}
                        modules={{toolbar: true}}
                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <label className='required fs-5 fw-semibold mb-2'>Sort</label>
                      <input
                        type='number'
                        autoComplete='off'
                        {...formik.getFieldProps('sort')}
                        className='form-control'
                        name='sort'
                      />
                    </div>

                    <div className='col-md-12 mt-5'>
                      <label className='fs-5 fw-semibold mb-2'>Image</label>
                      <input
                        type='file'
                        onChange={handleImageChange}
                        className='form-control'
                        name='image'
                        ref={fileInputRef}
                      />
                      <a
                        href={`${API_URL}/${selectedImage ? selectedImage.name : data.image}`}
                        target='_blank'
                      >
                        <img
                          src={`${
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : `${API_URL}/${data.image}`
                          }`}
                          alt='Image'
                          className='mt-5'
                          style={{height: '100px'}}
                        />
                      </a>
                    </div>
                    <div className='col-md-12 mt-10 text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting}
                        data-bs-dismiss={modal ? '' : 'modal'}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button type='button' className='btn btn-dark ms-3' data-bs-dismiss='modal'>
                        <span className='indicator-label'>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
