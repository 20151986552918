/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {useAuthenticatedRequest} from '../../Components/Requests'
import axios from 'axios'
import {toast} from 'react-toastify'

export const WhyBenyaCables = () => {
  const [loading, setLoading] = useState(false)
  const {editCmsRequests} = useAuthenticatedRequest()
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [data, setData] = useState<any>({})
  const endpoint = '/v1/cables/admin/cms?page_name=home_page&mode=admin'
  const url = '/v1/cables/admin/cms?page_name=home_page'
  const [selectedImage1, setSelectedImage1] = useState<File | null>(null);
  const [selectedImage2, setSelectedImage2] = useState<File | null>(null);
  const [selectedImage3, setSelectedImage3] = useState<File | null>(null);
  const [selectedImage4, setSelectedImage4] = useState<File | null>(null);


  useEffect(() => {
    axios.get(`${API_URL}${endpoint}`).then((response) => {
      setData(response.data.data.home_page)
      // Set initial values when data is available
      formik.setValues({
        why_benya_title_en: response.data.data.home_page.why_benya_title_en || '',
        why_benya_title_ar: response.data.data.home_page.why_benya_title_ar || '',
        section_div1_title_en: response.data.data.home_page.section_div1_title_en || '',
        section_div1_title_ar: response.data.data.home_page.section_div1_title_ar || '',
        section_div1_icon: response.data.data.home_page.section_div1_icon || '',
        section_div2_title_en: response.data.data.home_page.section_div2_title_en || '',
        section_div2_title_ar: response.data.data.home_page.section_div2_title_ar || '',
        section_div2_icon: response.data.data.home_page.section_div2_icon || '',
        section_div3_title_en: response.data.data.home_page.section_div3_title_en || '',
        section_div3_title_ar: response.data.data.home_page.section_div3_title_ar || '',
        section_div3_icon: response.data.data.home_page.section_div3_icon || '',
        section_div4_title_en: response.data.data.home_page.section_div4_title_en || '',
        section_div4_title_ar: response.data.data.home_page.section_div4_title_ar || '',
        section_div4_icon: response.data.data.home_page.section_div4_icon || '',
        page_name: 'home_page',
      })
    })
  }, [API_URL, endpoint])

  const formik = useFormik({
    initialValues: {
      page_name: 'home_page',
      why_benya_title_en: '',
      why_benya_title_ar: '',
      section_div1_title_en: '',
      section_div1_title_ar: '',
      section_div1_icon: '',
      section_div2_title_en: '',
      section_div2_title_ar: '',
      section_div2_icon: '',
      section_div3_title_en: '',
      section_div3_title_ar: '',
      section_div3_icon: '',
      section_div4_title_en: '',
      section_div4_title_ar: '',
      section_div4_icon: '',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await editCmsRequests(url, values)
        setLoading(false)
        toast.success('Form submitted successfully')
      } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('An error occurred:', error.response.data.message)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0];
    if (selectedFile) {
      switch (fieldName) {
        case 'section_div1_icon':
          setSelectedImage1(selectedFile);
          break;
        case 'section_div2_icon':
          setSelectedImage2(selectedFile);
          break;
        case 'section_div3_icon':
          setSelectedImage3(selectedFile);
          break;
        case 'section_div4_icon':
          setSelectedImage4(selectedFile);
          break;
        default:
          break;
      }
      formik.setFieldValue(fieldName, selectedFile);
    }
  };
  
  return (
    <>
      <div className='row mb-10'>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_add_form'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('why_benya_title_en')}
                  className='form-control'
                  name='why_benya_title_en'
                />
              </div>
              <div className='col-md-6'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('why_benya_title_ar')}
                  className='form-control'
                  name='why_benya_title_ar'
                />
              </div>

              <div className='col-md-12 mt-10'>
                <h2>Feature 1</h2>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Icon</label>
                <input
                  type="file"
                  onChange={(event) => handleImageChange(event, 'section_div1_icon')}
                  className="form-control"
                  name="section_div1_icon"
                    accept=".svg, .png, .jpg, .jpeg"
                />
                <a
                  href={
                    data &&
                    `${API_URL}/${
                      selectedImage1 ? selectedImage1.name : data.section_div1_icon
                    }`
                  }
                  target="_blank"
                >
                  <img
                  src={ data &&
                      `${
                        selectedImage1
                          ? URL.createObjectURL(selectedImage1)
                          : `${API_URL}/${data.section_div1_icon}`
                      }`
                    }
                    alt="Image"
                    className="mt-5"
                    style={{ height: "50px" }}
                  />
                </a>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div1_title_en')}
                  className='form-control'
                  name='section_div1_title_en'
                />
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div1_title_ar')}
                  className='form-control'
                  name='section_div1_title_ar'
                />
              </div>

              <div className='col-md-12 mt-10'>
                <h2>Feature 2</h2>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Icon</label>
                <input
                  type="file"
                  onChange={(event) => handleImageChange(event, 'section_div2_icon')}
                  className="form-control"
                  name="section_div2_icon"
                    accept=".svg, .png, .jpg, .jpeg"
                />
                <a
                  href={
                    data &&
                    `${API_URL}/${
                      selectedImage2 ? selectedImage2.name : data.section_div2_icon
                    }`
                  }
                  target="_blank"
                >
                  <img
                  src={ data &&
                      `${
                        selectedImage2
                          ? URL.createObjectURL(selectedImage2)
                          : `${API_URL}/${data.section_div2_icon}`
                      }`
                    }
                    alt="Image"
                    className="mt-5"
                    style={{ height: "50px" }}
                  />
                </a>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div2_title_en')}
                  className='form-control'
                  name='section_div2_title_en'
                />
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div2_title_ar')}
                  className='form-control'
                  name='section_div2_title_ar'
                />
              </div>

              <div className='col-md-12 mt-10'>
                <h2>Feature 3</h2>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Icon</label>
                <input
                  type="file"
                  onChange={(event) => handleImageChange(event, 'section_div3_icon')}
                  className="form-control"
                  name="section_div3_icon"
                    accept=".svg, .png, .jpg, .jpeg"
                />
                <a
                  href={
                    data &&
                    `${API_URL}/${
                      selectedImage3 ? selectedImage3.name : data.section_div3_icon
                    }`
                  }
                  target="_blank"
                >
                  <img
                  src={ data &&
                      `${
                        selectedImage3
                          ? URL.createObjectURL(selectedImage3)
                          : `${API_URL}/${data.section_div3_icon}`
                      }`
                    }
                    alt="Image"
                    className="mt-5"
                    style={{ height: "50px" }}
                  />
                </a>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div3_title_en')}
                  className='form-control'
                  name='section_div3_title_en'
                />
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div3_title_ar')}
                  className='form-control'
                  name='section_div3_title_ar'
                />
              </div>

              <div className='col-md-12 mt-10'>
                <h2>Feature 4</h2>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Icon</label>
                <input
                  type="file"
                  onChange={(event) => handleImageChange(event, 'section_div4_icon')}
                  className="form-control"
                  name="section_div4_icon"
                    accept=".svg, .png, .jpg, .jpeg"
                />
                <a
                  href={
                    data &&
                    `${API_URL}/${
                      selectedImage4 ? selectedImage4.name : data.section_div4_icon
                    }`
                  }
                  target="_blank"
                >
                  <img
                  src={ data &&
                      `${
                        selectedImage4
                          ? URL.createObjectURL(selectedImage4)
                          : `${API_URL}/${data.section_div4_icon}`
                      }`
                    }
                    alt="Image"
                    className="mt-5"
                    style={{ height: "50px" }}
                  />
                </a>
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title English</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div4_title_en')}
                  className='form-control'
                  name='section_div4_title_en'
                />
              </div>
              <div className='col-md-4 mt-5'>
                <label className='fs-5 fw-semibold mb-2'>Title Arabic</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('section_div4_title_ar')}
                  className='form-control'
                  name='section_div4_title_ar'
                />
              </div>
              <div className='col-md-12 mt-10 text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
