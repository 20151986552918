import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import DataTableExample from '../apps/datatable/Datatable'
import {TableColumn} from 'react-data-table-component'
import {Add} from './Add'
import {Edit} from './Edit'
import {deleteAction, actionsDetails} from '../CrudActions/index'

interface RowData {
  id: number
  page_name: string
  title: string
  meta_description: string
  keywords: string
  header_content: string
  image_alt_text: string
  canonical_url: string
  robots_meta: string
  schema_markup: string
}

const SeoPage = () => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [change, setChange] = useState(false)
  const endpoint = '/v1/cables/admin/seo'
  const ModalEdit = '#kt_modal_edit_Sub'
  const rowID = selectedRowId
  const actionsColumn: TableColumn<RowData> = actionsDetails(
    deleteAction,
    endpoint,
    setChange,
    change,
    ModalEdit,
    rowID,
    setSelectedRowId 
  )

  const dynamicColumns: TableColumn<RowData>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Page Name',
      selector: (row) => row.page_name,
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Meta Description',
      selector: (row) => row.meta_description,
      sortable: true,
    },
    {
      name: 'Keywords',
      selector: (row) => row.keywords,
      sortable: true,
    },
    {
      name: 'Header Content',
      selector: (row) => row.header_content,
      sortable: true,
    },
    {
      name: 'Image Alt Text',
      selector: (row) => row.image_alt_text,
      sortable: true,
    },
    {
      name: 'Canonical url',
      selector: (row) => row.canonical_url,
      sortable: true,
    },
    {
      name: 'Robots Meta',
      selector: (row) => row.robots_meta,
      sortable: true,
    },
    {
      name: 'Schema Markup',
      selector: (row) => row.schema_markup,
      sortable: true,
    }
    
  ]
  return (
    <>
      <DataTableExample
        endpoint={endpoint}
        columns={dynamicColumns}
        cardTitle='SEO'
        addButtonLabel='Add New SEO'
        ModalAdd='#kt_modal_add_Sub'
        actionsColumn={actionsColumn}
        change={change}
      />
      <Add change={change} setChange={setChange} endpoint={endpoint} />
      <Edit
        change={change}
        setChange={setChange}
        endpoint={endpoint}
        selectedRowId={selectedRowId}
      />
    </>
  )
}

const SeoWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SEO'})}</PageTitle>
      <SeoPage />
    </>
  )
}

export {SeoWrapper}
